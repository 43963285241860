import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';

import { ACTIONS } from '../../store';
import { SHIRT_COLORS, SHIRT_PLACEMENTS, SHIRT_SIZE_OPTIONS, SHIRT_VARIANTS } from '../../constants';
import { createNewOrder, getShirtSizeLabelForValue, getTotalQuantity, getVariantForColorAndSize } from '../../util/orders';

import './shirt-config.css';

class ShirtConfig extends Component {
  static propTypes = {
    addShirtToOrders: PropTypes.func.isRequired,
    haveSoflooRenders: PropTypes.bool.isRequired,
    history: PropTypes.any,
    orders: PropTypes.array.isRequired,
    selectNewShirtPlacement: PropTypes.func.isRequired,
    selectNewVariantId: PropTypes.func.isRequired,
    soflooRenderUrl: PropTypes.string.isRequired,
    soflooSeed: PropTypes.number.isRequired,
    soflooVersion: PropTypes.string.isRequired,
    shirtPlacement: PropTypes.string.isRequired,
    variantId: PropTypes.string.isRequired,
    shareShirtButtonClicked: PropTypes.func.isRequired
  }

  addToBagClicked = () => {
    if (this.props.haveSoflooRenders) {
      const {
        addShirtToOrders,
        shirtPlacement,
        variantId,
        soflooRenderUrl,
        soflooSeed,
        soflooVersion
      } = this.props;

      const newOrder = createNewOrder(variantId, shirtPlacement, soflooRenderUrl, soflooSeed, soflooVersion);
      addShirtToOrders(newOrder);
      this.props.history.push('/checkout');
    } else {
      alert('Sorry! Please wait for the mockups to be generated before getting your shirt.')
    }
  }

  selectNewShirt = (shirtColor, shirtSize) => {
    const newVariantId = getVariantForColorAndSize(shirtColor, shirtSize);
    this.props.selectNewVariantId(newVariantId);
  }

  render() {
    const {
      orders,
      selectNewShirtPlacement,
      shirtPlacement,
      variantId
    } = this.props;

    const shirtColor = SHIRT_VARIANTS[variantId].color;
    const shirtSize = SHIRT_VARIANTS[variantId].size;

    const ordersSum = getTotalQuantity(orders);

    return (
      <div className="shirt-config">
        <Link className="sc-cart-button" to="/checkout">
          <svg className="sc-cart-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path className="sc-cart-icon" d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z" /></svg>
          {ordersSum > 0 && <div className="red-indicator">{ordersSum}</div>}
        </Link>
        <div className="sc-tee-color">
          <div className="sc-option-title">
            tee color: 
          </div>
          {Object.keys(SHIRT_COLORS).map((color) => {
            return <div
              className={`sc-color-option ${shirtColor === color ? 'sc-color-selected' : ''}`}
              key={color}
              title={color}
              onClick={() => this.selectNewShirt(color, shirtSize)}
              style={{backgroundColor: SHIRT_COLORS[color]}}
            />;
          })}
        </div>
        <div className="sc-design-placement">
          <div className="sc-option-title">
            design placement: 
          </div>
          {Object.keys(SHIRT_PLACEMENTS).map(placement => {
            return <img
              className={`sc-placement-option ${shirtPlacement === placement ? 'sc-placement-selected' : ''}`}
              key={placement}
              alt={placement}
              onClick={() => selectNewShirtPlacement(placement)}
              src={`${SHIRT_PLACEMENTS[placement].thumbnailImage}.png`}
            />;
          })}
        </div>
        <div className="sc-shirt-size">
          <div className="sc-option-title">
            size: 
          </div>
          <div className="sc-size-selector">
            <Select
              value={{
                label: getShirtSizeLabelForValue(shirtSize),
                value: shirtSize
              }}
              classNamePrefix="select"
              isSearchable={false}
              onChange={(selectedOption) => this.selectNewShirt(shirtColor, selectedOption.value)}
              options={SHIRT_SIZE_OPTIONS}
            />
          </div>
        </div>
        <div
          className="sofloo-button sc-action-button"
          onClick={this.addToBagClicked}
        >
          add to bag
        </div>
        <div
          className="sofloo-button-inverse sc-action-button"
          onClick={this.props.shareShirtButtonClicked}
        >
          share tee
        </div>
        <Link
          className="generative-faq"
          to="/faq"
          target="_blank"
        >
          faq
        </Link>
        <Link
          className="generative-contact-us"
          to="/contact"
          target="_blank"
        >
          contact
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  haveSoflooRenders: !!state.soflooRenders,
  orders: state.orders,
  shirtPlacement: state.shirtPlacement,
  soflooRenderUrl: state.soflooRenderUrl,
  soflooSeed: state.soflooSeed,
  soflooVersion: state.soflooVersion,
  variantId: state.variantId
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    addShirtToOrders: newOrder => ({
      type: ACTIONS.ADD_TO_ORDERS,
      newOrder
    }),
    selectNewShirtPlacement: newPlacement => ({
      type: ACTIONS.SET_SHIRT_PLACEMENT,
      shirtPlacement: newPlacement
    }),
    selectNewVariantId: newVariantId => ({
      type: ACTIONS.SET_SHIRT_VARIANT,
      newVariantId: newVariantId
    }),
    showContactUsWindow: () => ({
      type: ACTIONS.SET_CONTACT_WINDOW,
      show: true
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShirtConfig);
