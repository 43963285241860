import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { soflooHeight, soflooWidth } from '../../../constants';
import { generateLayoutBySeedAndVersion } from '../../../sofloo/layouts';

import ACTIONS from '../../../store/actions';

import Sofloo from '../sofloo';

/**
 * When sofloo is loaded using a share url we off-the-screen render the design to use.
 * This class manages that hidden render.
 */
class HiddenSofloo extends Component {
  static propTypes = {
    soflooRendered: PropTypes.func.isRequired,
    seed: PropTypes.number.isRequired,
    setSoflooAttributes: PropTypes.func.isRequired,
    version: PropTypes.string.isRequired
  };

  state = {
    building: true
  }

  componentDidMount() {
    this.mounted = true;
    this.generateSofloo();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  generateSofloo = () => {
    setTimeout(() => {
      const {
        shapes
      } = generateLayoutBySeedAndVersion(soflooWidth, soflooHeight, this.props.seed, this.props.version);
      
      this.sofloo = <Sofloo
        height={soflooHeight}
        setSoflooRef={ref => {this.soflooRef = ref; this.props.setSoflooAttributes(this.props.seed, this.props.version, ref)}}
        shapes={shapes}
        width={soflooWidth}
      />;

      this.setState({
        building: false
      });

      // Once we've rendered we tell parent we've done it.
      setTimeout(() => {
        if (this.mounted) {
          this.props.soflooRendered(this.soflooRef, this.props.seed, this.props.version);
        }
      }, 5);
    }, 5);
  }

  soflooRef = null;

  render() {
    const { building } = this.state;

    return (
      <div className="hidden-sofloo">
        {!building && this.sofloo}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setSoflooAttributes: (soflooSeed, soflooVersion, soflooRef) => ({
      type: ACTIONS.SET_SOFLOO,
      soflooSeed,
      soflooVersion,
      soflooRef
    })
  }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(HiddenSofloo);
