import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { bindActionCreators } from 'redux';

import './pay.css';

import { ACTIONS } from '../../store';
import { PAYMENT_WINDOWS } from '../../constants';

import BuildOrder from '../build-order';
import Checkout from '../checkout';
// import CloseButton from '../close-button';
import Confirmation from '../confirmation';
import CustomerInformation from '../customer-information';
import Shipping from '../shipping'

class Pay extends Component {
  static propTypes = {
    history: PropTypes.any.isRequired,
    // makingOrder: PropTypes.bool.isRequired,
    paymentWindow: PropTypes.string.isRequired,
    setPaymentWindow: PropTypes.func.isRequired
  }

  render() {
    const {
      history,
      // makingOrder,
      paymentWindow
    } = this.props;

    return (
      <Elements>
        <div className="pay-container">
          <div className="pay">
            {paymentWindow === PAYMENT_WINDOWS.BUILD_ORDER && (
              <BuildOrder history={history} />
            )}
            {paymentWindow === PAYMENT_WINDOWS.CHECKOUT && (
              <Checkout history={history} />
            )}
            {paymentWindow === PAYMENT_WINDOWS.CUSTOMER_INFORMATION && (
              <CustomerInformation history={history} />
            )}
            {paymentWindow === PAYMENT_WINDOWS.SHIPPING && (
              <Shipping history={history} />
            )}
            {paymentWindow === PAYMENT_WINDOWS.CONFIRMATION && (
              <Confirmation history={history} />
            )}
          </div>
        </div>
      </Elements>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  makingOrder: state.makingOrder,
  paymentWindow: state.paymentWindow
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setPaymentWindow: newPaymentWindow => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow
    })
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pay);
