import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { ACTIONS } from '../../store';
import { soflooWidth, soflooHeight, EMPTY_SOFLOO_SEED, VERSIONS } from '../../constants';
import { generateRandomLayout, generateLayoutBySeedAndVersion } from '../../sofloo/layouts';
// import { isOnMobile } from '../../util/mobile';
import WebService from '../../web-service';

import Loader from '../loader';
import ShoppingCart from '../shopping-cart';
import Sofloo from './sofloo';

import './generative.css';

const randomizeAlgorithm = VERSIONS.FULL_RANDOM;
// const randomizeAlgorithm = VERSIONS.LINES;

// MS that we will wait before rendering when a resizing occurs.
const resizeTimeWindow = 1000;

class Generative extends Component {
  static propTypes = {
    purchaseShirtClicked: PropTypes.func.isRequired,
    showContactUsWindow: PropTypes.func.isRequired,
    soflooSeed: PropTypes.number,
    soflooVersion: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.currentIndexSoflooRenders = 0;
    // Sofloo only does it's nice rendering right on chrome.
    this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
    this.resizeTimer = null;
    this.soflooRef = null;
    this.soflooRenders = [];
    // Array of the indexes of visited soflooRenders.
    this.visitedSoflooRenders = [];
  }

  state = {
    artImageLoaded: false,
    building: true,
    currentSoflooImageUrl: '', // Only for mobile.
    fetchingSoflooRenders: false,
    haveSoflooRenders: false, // Only for mobile.
    resizing: false,
    seed: '',
    version: ''
  }

  onWindowResize = () => {
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }

    this.setState({
      resizing: true
    });

    this.resizeTimer = setTimeout(() => {
      this.setState({
        resizing: false
      });

      this.resizeTimer = null;
    }, resizeTimeWindow);
  }

  componentWillUnmount() {
    if (this.isChrome) {
      window.removeEventListener('resize', this.onWindowResize);
    }
  }

  componentDidMount = () => {
    if (this.isChrome) {
      this.generateNewSofloo(!!this.props.soflooSeed);
      window.addEventListener('resize', this.onWindowResize);
    } else {
      this.getSoflooRenders();
    }
  }

  artImageOnLoad = () => {
    this.setState({
      artImageLoaded: true
    });
  }

  getSoflooRenders = async () => {
    this.setState({
      fetchingSoflooRenders: true,
      unableToGetSoflooRenders: false
    });

    try {
      this.soflooRenders = await WebService.getSoflooRenders();
      this.visitedSoflooRenders = [];

      if (!this.soflooRenders || this.soflooRenders.length < 1) {
        // When we've got no images to show f it just show them trash algo.
        this.generateNewSofloo();
        return;
      }

      this.currentIndexSoflooRenders = 0;

      this.setState({
        artImageLoaded: false,
        currentSoflooImageUrl: this.soflooRenders[0].fUrl,
        fetchingSoflooRenders: false,
        seed: this.soflooRenders[0].s,
        version: this.soflooRenders[0].v
      });
    } catch (e) {
      console.log('Error fetching sofloo rendered images:', e);
      this.setState({
        fetchingSoflooRenders: false,
        unableToGetSoflooRenders: true
      });

      return;
    }
  }

  generateNewSofloo = existingSofloo => {
    this.setState({ building: true });

    if (!this.isChrome && this.soflooRenders.length > 0) {
      this.currentIndexSoflooRenders++;

      if (this.currentIndexSoflooRenders >= this.soflooRenders.length) {
        // No more sofloos in this pre-rendered sample set. Let's get more.
        this.getSoflooRenders();
      } else {
        const sofloo = this.soflooRenders[this.currentIndexSoflooRenders];
        
        this.setState({
          artImageLoaded: false,
          currentSoflooImageUrl: sofloo.fUrl,
          seed: sofloo.s,
          version: sofloo.v
        });
      }
      
      return;
    }

    setTimeout(() => {
      let seed;
      let shapes;
      let version;

      if (existingSofloo && this.props.soflooSeed !== EMPTY_SOFLOO_SEED) {
        const layout = generateLayoutBySeedAndVersion(soflooWidth, soflooHeight, this.props.soflooSeed, this.props.soflooVersion);

        seed = layout.seed;
        shapes = layout.shapes;
        version = layout.version;
      } else {
        const layout = generateRandomLayout(soflooWidth, soflooHeight, randomizeAlgorithm);

        seed = layout.seed;
        shapes = layout.shapes;
        version = layout.version;
      }

      this.sofloo = <Sofloo
        height={soflooHeight}
        setSoflooRef={ref => {this.soflooRef = ref;}}
        shapes={shapes}
        width={soflooWidth}
      />;

      this.setState({
        building: false,
        seed,
        version
      });
    }, 10);
  }

  generateClicked = () => {
    this.generateNewSofloo(false);
  }

  render() {
    const {
      artImageLoaded,
      currentSoflooImageUrl,
      fetchingSoflooRenders,
      building,
      resizing,
      seed,
      unableToGetSoflooRenders,
      version
    } = this.state;

    console.log('render seed', seed);

    const isChrome = this.isChrome;
    
    return (
      <div className="generative">
        <ShoppingCart
          hideIfEmpty
        />
        <div className="row">
          {isChrome && (
            <React.Fragment>
              {(building || resizing) && (
                <div className="generative-loading-container">
                  <div className="generative-loading-text">
                    rendering...
                  </div>
                </div>
              )}
              {!building && !resizing && this.sofloo}
            </React.Fragment>
          )}
          {!isChrome && (
            <div className="generative-mobile-container">
              {fetchingSoflooRenders && (
                <div className="generative-loading-container">
                  <div className="generative-loading-text">
                    <Loader />
                  </div>
                </div>
              )}
              {!fetchingSoflooRenders && unableToGetSoflooRenders && (
                <div className="generative-loading-container">
                  <div className="generative-loading-text">
                    Unable to fetch sofloo...
                  </div>
                  <div className="generative-try-again" onClick={this.getSoflooRenders}>
                    Try again :D
                  </div>
                </div>
              )}
              {!fetchingSoflooRenders && !unableToGetSoflooRenders && (
                <React.Fragment>
                  {!artImageLoaded && (
                    <div className="generative-sofloo-image-loader-container">
                      <Loader />
                    </div>
                  )}
                  <img
                    className={`generative-sofloo-image ${artImageLoaded ? '' : 'generative-sofloo-image-not-loaded'}`}
                    src={currentSoflooImageUrl}
                    onLoad={this.artImageOnLoad}
                    alt="sofloo"
                  />
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <div className="generative-buttons">
          <div
            className="generative-generate-button sofloo-button"
            onClick={this.generateClicked}
          >generate art</div>
          <div
            className="generative-build-tee-button sofloo-button-inverse"
            onClick={() => this.props.purchaseShirtClicked(this.soflooRef, seed, version)}
          >build a tee</div>
          <div
            className="generative-about-info"
          >
            Sofloo is a generative art algorithm. Click generate - there's a 1 in 999,999,999,999 chance you'll get the same piece twice. {!isChrome && '* Unfortunately sofloo\'s algorithm\'s rendering strategy only works on chrome desktop. For now we\'ll be fetching pre-rendered images.'}
          </div>
          <Link
            className="generative-faq"
            to="/faq"
            target="_blank"
          >
            faq
          </Link>
          <Link
            className="generative-contact-us"
            to="/contact"
            target="_blank"
          >
            contact
          </Link>
          <a
            className="generative-view-algorithm"
            href="https://anemy.github.io/sofloo-spot"
            target="_blank"
            rel="noopener noreferrer"
          >
            view full algorithm
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    showContactUsWindow: () => ({
      type: ACTIONS.SET_CONTACT_WINDOW,
      show: true
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Generative);
