import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import './shirt-page.css';

import Header from '../header';
import HiddenSofloo from '../generative/sofloo/hidden-sofloo';
import Share from '../share';
import ShirtConfig from '../shirt-config';
import ShirtPreview from '../shirt-preview';

import ACTIONS from '../../store/actions';
import { imageOutputWidth, imageOutputHeight, SHIRT_PLACEMENTS, SHIRT_VARIANTS } from '../../constants';
import { getSoflooUrl, getSharingSeedFromURL, getSharingVersionFromURL } from '../../util/url';
import WebService from '../../web-service';

class ShirtPage extends Component {
  static propTypes = {
    haveSoflooGenerated: PropTypes.bool.isRequired,
    haveSoflooRenders: PropTypes.bool.isRequired,
    setSoflooAttributes: PropTypes.func.isRequired,
    setSoflooRenders: PropTypes.func.isRequired,
    soflooRef: PropTypes.any,
    soflooRenderUrl: PropTypes.string.isRequired,
    soflooSeed: PropTypes.number,
    soflooVersion: PropTypes.string,
    variantId: PropTypes.string.isRequired
  };

  state = {
    failedToGenerateMockups: false,
    mockupCreationIsTakingALongTime: false,
    shareUrl: '',
    showShareWindow: false
  };

  componentWillMount = () => {
    const soflooSeed = getSharingSeedFromURL();
    const soflooVersion = getSharingVersionFromURL();

    this.setState({
      shareUrl: '',
      showShareWindow: false
    });

    if (soflooSeed && soflooVersion && (this.props.soflooSeed !== soflooSeed || this.props.soflooVersion !== soflooVersion)) {
      // If the user is loading a shared url then we load up the art & start mocking.
      this.hiddenSofloo = <HiddenSofloo
        seed={soflooSeed}
        soflooRendered={this.generateAndViewMockups}
        version={soflooVersion}
      />;
      this.props.setHaveSoflooGenerated(false);

    } else {
      this.generateAndViewMockups();
    }

    this.unlisten = this.props.history.listen((location, action) => {
      const urlSoflooSeed = getSharingSeedFromURL(location.search);
      const urlSoflooVersion = getSharingVersionFromURL(location.search);

      if (location.pathname !== '/sofloo') {
        return;
      }

      if (urlSoflooSeed !== this.props.soflooSeed || urlSoflooVersion !== this.props.soflooVersion) {
        this.setState({
          shareUrl: '',
          showShareWindow: false
        });
        this.props.setSoflooAttributes(urlSoflooSeed, urlSoflooVersion);
        
        this.hiddenSofloo = <HiddenSofloo
          seed={urlSoflooSeed}
          soflooRendered={this.generateAndViewMockups}
          version={urlSoflooVersion}
        />;

        this.props.setHaveSoflooGenerated(false);
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  hideShareWindow = () => {
    this.setState({
      showShareWindow: false
    });
  }

  showShareWindow = () => {
    this.setState({
      showShareWindow: true
    });
  }

  generateAndViewMockups = () => {
    this.setState({
      failedToGenerateMockups: false
    });

    this.props.setHaveSoflooGenerated(false);
    this.props.setSoflooRenders(null);

    this.generateSoflooImage();

    document.title = `Sofloo - ${this.props.soflooSeed}`;
    const baseURL = `${window.location.origin}${window.location.pathname}`;
    const shareUrl = `${baseURL}${getSoflooUrl(this.props.soflooSeed, this.props.soflooVersion)}`;

    this.props.setHaveSoflooGenerated(true);

    this.setState({
      shareUrl
    });
  }

  // Example / text mockup images.
  // this.mockups = {
  //   full: 'https://sofloo.s3.amazonaws.com/full-2899890-top-1-cr-gr.png',
  //   pocket: 'https://sofloo.s3.amazonaws.com/pocket-2899890-top-1-cr-gr.png'
  // };
  // this.setState({
  //   mockupCreationIsTakingALongTime: false,
  //   failedToGenerateMockups: false
  // });
  // return;

  generateSoflooImage = async () => {
    const { soflooRef, soflooSeed, soflooVersion } = this.props;

    // Clear the sofloo renders.
    this.props.setSoflooRenders(null);

    this.setState({
      failedToGenerateMockups: false,
      mockupCreationIsTakingALongTime: false
    });

    this.currentMarkupBuild++;
    const mockupBuildVersion = this.currentMarkupBuild;

    let doesImageAlreadyExist = false;
    try {
      // Note we do pocket first because it is the second image uploaded.
      doesImageAlreadyExist = await WebService.doesImageExist('pocket', soflooSeed, soflooVersion);
      if (doesImageAlreadyExist) {
        doesImageAlreadyExist = await WebService.doesImageExist('full', soflooSeed, soflooVersion);
      }
    } catch (e) {
      doesImageAlreadyExist = false;
      // console.log('Error attempting to fetch existing image:', e);
    }

    // Before we create images we check to see if an image already exists for
    // this seed and version.
    // Only update the UI if the mockups we're requesting are the most recent request.
    if (doesImageAlreadyExist && mockupBuildVersion === this.currentMarkupBuild) {
      const pocketImageUrl = WebService.getImageUrl('pocket', soflooSeed, soflooVersion);

      this.props.setSoflooRenders({
        full: WebService.getImageUrl('full', soflooSeed, soflooVersion),
        pocket: pocketImageUrl
      });

      this.setState({
        failedToGenerateMockups: false
      });
      return;
    }

    if (!soflooRef) {
      alert('Unable to generate a shirt from your art. Please refresh and try again.');
      return;
    }

    // TODO: Create a timeout to make sure we don't spend forever uploading an image that never makes it.

    const startTime = Date.now();
    const svgData = new XMLSerializer().serializeToString(soflooRef);

    const canvas = document.createElement('canvas');
    canvas.width = imageOutputWidth;
    canvas.height = imageOutputHeight;
    const ctx = canvas.getContext('2d');

    const img = document.createElement('img');

    img.setAttribute('src', `data:image/svg+xml;base64,${btoa(svgData)}`);
    if (Date.now() - startTime > 1000 * 3) {
      this.setState({
        mockupCreationIsTakingALongTime: true
      });
    }

    img.onload = async () => {
      // Note the ordering of creating/uploading full first and then pocket.
      const fullPlacement = SHIRT_PLACEMENTS.full;
      ctx.drawImage(img, fullPlacement.left, fullPlacement.top, fullPlacement.width, fullPlacement.height);
      const fullImageDataURL = canvas.toDataURL();

      if (Date.now() - startTime > 1000 * 3) {
        this.setState({
          mockupCreationIsTakingALongTime: true
        });
      }

      ctx.clearRect(0, 0, imageOutputWidth, imageOutputHeight);

      const pocketPlacement = SHIRT_PLACEMENTS.pocket;
      ctx.drawImage(img, pocketPlacement.left, pocketPlacement.top, pocketPlacement.width, pocketPlacement.height);

      const pocketImageDataURL = canvas.toDataURL();

      if (Date.now() - startTime > 1000 * 3) {
        this.setState({
          mockupCreationIsTakingALongTime: true
        });
      }  

      try {
        const fullImageUrl = await WebService.handleFileUpload('full', fullImageDataURL, soflooSeed, soflooVersion);
        const pocketImageUrl = await WebService.handleFileUpload('pocket', pocketImageDataURL, soflooSeed, soflooVersion);

        // Only update the UI if the mockups we're requesting are the most recent request.
        if (mockupBuildVersion === this.currentMarkupBuild) {
          this.props.setSoflooRenders({
            full: fullImageUrl,
            pocket: pocketImageUrl
          });

          this.setState({
            failedToGenerateMockups: false
          });
        }
      } catch (e) {
        console.log('failed to generr: ', e);
        this.setState({
          failedToGenerateMockups: true
        });
        return;
      }
    };
  }

  recreateMockups = () => {
    this.setState({
      failedToGenerateMockups: false
    });

    // Clear the sofloo renders.
    this.props.setSoflooRenders(null);

    setTimeout(() => {
      this.generateAndViewMockups(this.props.soflooRef, this.props.soflooSeed, this.props.soflooVersion);
    }, 2);
  }

  // Version counter to ensure our async requests for mockups, when returned, update the ui if they're
  // the most recent request.
  currentMarkupBuild = 0;

  render() {
    const {
      failedToGenerateMockups,
      mockupCreationIsTakingALongTime,
      shareUrl,
      showShareWindow
    } = this.state;

    const {
      haveSoflooGenerated,
      haveSoflooRenders,
      history,
      soflooRenderUrl,
      variantId
    } = this.props;

    return (
      <div className="home">
        <Header />
        {!haveSoflooGenerated && this.hiddenSofloo}
        <div className="row home-content-window">
          <div className="col-md-8 home-shirt-window">
            <ShirtPreview
              haveSoflooGenerated={haveSoflooGenerated}
              haveSoflooRenders={haveSoflooRenders}
              failedToGenerateMockups={failedToGenerateMockups}
              mockupCreationIsTakingALongTime={mockupCreationIsTakingALongTime}
              recreateMockups={this.recreateMockups}
              soflooRenderUrl={soflooRenderUrl}
              shirtColor={SHIRT_VARIANTS[variantId].color}
            />
            <div className="home-regenerate-button-container">
              <Link
                className="sofloo-button-inverse"
                to="/"
              ><span className="home-back-button-icon">&lsaquo;</span> regenerate</Link>
            </div>
          </div>
          <div className="col-md-4 home-shirt-config-container">
            <ShirtConfig
              shareShirtButtonClicked={this.showShareWindow}
              history={history}
            />
          </div>
        </div>
        {showShareWindow && <Share
          hideShareWindow={this.hideShareWindow}
          shareUrl={shareUrl}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  haveSoflooRenders: !!state.soflooRenders,
  haveSoflooGenerated: state.haveSoflooGenerated,
  soflooRef: state.soflooRef,
  soflooRenderUrl: state.soflooRenderUrl,
  soflooSeed: state.soflooSeed,
  soflooVersion: state.soflooVersion,
  variantId: state.variantId
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setSoflooRenders: soflooRenders => ({
      type: ACTIONS.SET_SOFLOO_RENDERS,
      soflooRenders
    }),
    setHaveSoflooGenerated: haveSoflooGenerated => ({
      type: ACTIONS.SET_HAVE_SOFLOO_GENERATED,
      haveSoflooGenerated
    }),
    setSoflooAttributes: (soflooSeed, soflooVersion, soflooRef) => ({
      type: ACTIONS.SET_SOFLOO,
      soflooSeed,
      soflooVersion,
      soflooRef
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShirtPage);
