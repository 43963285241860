import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';

import './build-order.css';

import Coupon from '../coupon';
import Input from '../inputs';
import ShirtPreview from '../shirt-preview';

import ACTIONS from '../../store/actions';
import {
  EMPTY_SOFLOO_SEED,
  SHIRT_UNIT_PRICE,
  SHIRT_VARIANTS,
  SHIRT_SIZE_OPTIONS,
  PAYMENT_WINDOWS
} from '../../constants';
import { getShirtSizeLabelForValue, getVariantForColorAndSize } from '../../util/orders';
import { getSoflooUrl } from '../../util/url';

class BuildOrder extends Component {
  static propTypes = {
    continueToCustomerInformation: PropTypes.func.isRequired,
    orders: PropTypes.array.isRequired,
    setHaveSoflooGenerated: PropTypes.func.isRequired,
    setShirtPlacement: PropTypes.func.isRequired,
    setShirtVariant: PropTypes.func.isRequired,
    setSoflooAttributes: PropTypes.func.isRequired,
    soflooSeed: PropTypes.number.isRequired,
    soflooVersion: PropTypes.string.isRequired,
    subtotal: PropTypes.number.isRequired,
    updateOrder: PropTypes.func.isRequired
  }

  state = {
    errorContinuingWithOrder: null
  }

  componentDidMount() {
    document.title = `Sofloo - Bag`;
  }

  backToShirtClicked = () => {
    const { soflooSeed, soflooVersion } = this.props;
    if (soflooSeed !== EMPTY_SOFLOO_SEED) {
      this.props.history.push(getSoflooUrl(soflooSeed, soflooVersion));
    } else {
      this.props.history.push('/');
    }
  }

  handleSelectSize = (orderIndex, newSize) => {
    const newOrders = [...this.props.orders];

    const newVariantId = getVariantForColorAndSize(SHIRT_VARIANTS[this.props.orders[orderIndex].variantId].color, newSize);
    newOrders[orderIndex].variantId = newVariantId;

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateOrder(newOrders);
  }

  handleSelectQuantity = (orderIndex, newQuantity) => {
    const newOrders = [...this.props.orders];

    newOrders[orderIndex].quantity = newQuantity;

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateOrder(newOrders);
  }

  removeOrder = orders => {
    let newOrders = [...this.props.orders];
    newOrders = _.without(newOrders, ...orders);

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateOrder(newOrders);
  }

  handleQuantityBlur = (orderIndex, newQuantity) => {
    if (!newQuantity && newQuantity !== 0) {
      this.handleSelectQuantity(orderIndex, 0);
    }
  }

  getTotalOrderCount = () => {
    const { orders } = this.props;
    let totalOrderCount = 0;
    for (let i = 0; i < orders.length; i++) {
      totalOrderCount += Number(orders[i].quantity);
    }
    return totalOrderCount;
  }

  // True if this form is valid.
  validate = () => {
    const { orders } = this.props;

    let totalOrderCount = 0;
    for (let i = 0; i < orders.length; i++) {
      if (!orders[i].quantity || isNaN(orders[i].quantity) || Number(orders[i].quantity) < 1) {
        this.setState({
          errorContinuingWithOrder: `Invalid order quantity: "${orders[i].quantity}".`
        });
        return false;
      }
      totalOrderCount += Number(orders[i].quantity);
    }
  
    if (totalOrderCount <= 0) {
      this.setState({
        errorContinuingWithOrder: 'Please add a shirt before checking out.'
      });
      return false;
    }
  
    if (totalOrderCount > 20) {
      this.setState({
        errorContinuingWithOrder: 'We currently do not allow over 20 shirts in one order.'
      });
      return false;
    }

    return true;
  }

  handleValidate = e => {
    e.preventDefault();
    const valid = this.validate();
    
    if (valid) {
      this.props.continueToCustomerInformation();
    }
  }

  getCombinedItems = () => {
    const { orders } = this.props;
    let combinedItems = [];
    _.each(orders, (order, i) => {
      const variant = SHIRT_VARIANTS[order.variantId];
      const index = _.findIndex(combinedItems, (o) => {
        return o.imageTitle === order.imageTitle && o.color === variant.color;
      });
      if (index >= 0) {
        combinedItems[index].orders.push(order);
        combinedItems[index].totalQuantity += Number(order.quantity);
        combinedItems[index].orderIndexes.push(i);
        if (order.lastModifiedAt > combinedItems[index].lastModifiedAt) combinedItems[index].lastModifiedAt = order.lastModifiedAt;
      } else {
        combinedItems.push({
          shirtColor: variant.color,
          shirtPlacement: order.shirtPlacement,
          soflooRenderUrl: order.soflooRenderUrl,
          soflooSeed: order.soflooSeed,
          soflooVersion: order.soflooVersion,
          orders: [order],
          totalQuantity: Number(order.quantity),
          orderIndexes: [i],
          lastModifiedAt: order.lastModifiedAt,
          variantId: order.variantId
        });
      }
    });
    return combinedItems;
  }

  shirtPreviewClicked = item => {
    const {
      setSoflooAttributes,
      setHaveSoflooGenerated,
      setShirtPlacement,
      setShirtVariant
    } = this.props;

    setHaveSoflooGenerated(false);
    setSoflooAttributes(item.soflooSeed, item.soflooVersion);
    setShirtVariant(item.variantId);
    setShirtPlacement(item.shirtPlacement);
    this.props.history.push(getSoflooUrl(item.soflooSeed, item.soflooVersion));
  }

  render() {
    const { errorContinuingWithOrder } = this.state;
    const { subtotal, soflooSeed } = this.props;

    const combinedItems = this.getCombinedItems();

    return (
      <div className="purchase">
        <div
          className="pay-back-button"
          onClick={this.backToShirtClicked}
        >
          <span className="pay-back-button-icon">&lsaquo;</span> {soflooSeed === EMPTY_SOFLOO_SEED ? 'back to sofloo' : 'back to shirt'}
        </div>
        <div className="pay-title">your sofloo bag</div>
        {(!combinedItems || combinedItems.length === 0) && (
          <div className="purchase-empty">
            your bag is empty
          </div>
        )}
        {combinedItems.map((item, index) => {
          const id = `${item.variantId}_${index}_${item.soflooSeed}_${item.soflooVersion}`;
          return (
            <div
              className="purchase-info-container row"
              key={id}
            >
              <div className="col-md-6">
                <button
                  className="purchase-shirt-preview"
                  onClick={() => this.shirtPreviewClicked(item)}
                >
                  <ShirtPreview
                    failedToGenerateMockups={false}
                    haveSoflooGenerated
                    haveSoflooRenders
                    soflooRenderUrl={item.soflooRenderUrl}
                    shirtColor={item.shirtColor}
                  />
                </button>
              </div>
              <div className="col-md-6">
                {item.orders.map((order, orderIndex) => {
                  const { size } = SHIRT_VARIANTS[order.variantId];
                  return (
                    <div className="row" key={item.orderIndexes[orderIndex]}>
                      <div className="col-5 purchase-input-item purchase-input-item-topmargin">
                        <Select
                          value={{
                            label: getShirtSizeLabelForValue(size),
                            value: size
                          }}
                          classNamePrefix="select"
                          isSearchable={false}
                          onChange={selectedOption => this.handleSelectSize(item.orderIndexes[orderIndex], selectedOption.value)}
                          options={SHIRT_SIZE_OPTIONS}
                        />
                      </div>
                      <div className="col-3 purchase-input-item">
                        <Input
                          label="Quantity"
                          id={`quantity_${size}_${id}`}
                          type="number"
                          value={order.quantity}
                          onChange={e => this.handleSelectQuantity(item.orderIndexes[orderIndex], e.target.value)}
                          onBlur={e => this.handleQuantityBlur(item.orderIndexes[orderIndex], e.target.value)}
                          flex
                        />
                      </div>
                      <div className="col-4 purchase-input-item">
                        <button
                          className="purchase-button purchase-button-red"
                          onClick={() => this.removeOrder([order])}
                        >Remove</button>
                      </div>
                      {/* Match this button lower down. */}
                    </div>
                  );
                })}
                <div className="purchase-flex-row purchase-space-between">
                  {combinedItems.length > 1 && (
                    <div className="purchase-info-item">
                      <label>price</label>
                      <p className="purchase-money-text">${SHIRT_UNIT_PRICE * (item.totalQuantity || 0)} <span className="gray">{(item.totalQuantity || 0) > 1 ? `(${(item.totalQuantity || 0)} x $${SHIRT_UNIT_PRICE})` : null}</span></p>
                    </div>
                  )}
                  {item.orders.length > 1 && (
                    <button className="purchase-button purchase-button-red" onClick={() => this.removeOrder(item.orders)}>Remove All</button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <Coupon />
        {errorContinuingWithOrder && (
          <div className="pay-error-message">
            {errorContinuingWithOrder}
          </div>
        )}
        <div className="purchase-controls-bar">
          <div className="purchase-info-item">
            <label>subtotal</label>
            <p className="purchase-money-text">${subtotal || 0} <span className="gray">({this.getTotalOrderCount() || 0} x ${SHIRT_UNIT_PRICE})</span></p>
          </div>
          <div className="sofloo-button" onClick={this.handleValidate}>continue</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.orders,
  soflooSeed: state.soflooSeed,
  soflooVersion: state.soflooVersion,
  subtotal: state.subtotal
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    continueToCustomerInformation: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.CUSTOMER_INFORMATION
    }),
    setHaveSoflooGenerated: haveSoflooGenerated => ({
      type: ACTIONS.SET_HAVE_SOFLOO_GENERATED,
      haveSoflooGenerated
    }),
    setProcessingImages: (processedImagesId, imagesAreProcessed) => ({
      type: ACTIONS.SET_PROCESSING_IMAGES,
      processedImagesId,
      imagesAreProcessed
    }),
    setShirtPlacement: newPlacement => ({
      type: ACTIONS.SET_SHIRT_PLACEMENT,
      shirtPlacement: newPlacement
    }),
    setShirtVariant: newVariantId => ({
      type: ACTIONS.SET_SHIRT_VARIANT,
      newVariantId: newVariantId
    }),
    setSoflooAttributes: (soflooSeed, soflooVersion) => ({
      type: ACTIONS.SET_SOFLOO,
      soflooSeed,
      soflooVersion
    }),
    updateOrder: newOrders => ({
      type: ACTIONS.UPDATE_ORDERS,
      newOrders
    }),
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildOrder);

