import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import history from './util/history';

import About from './components/about';
import ArtPage from './components/art-page';
import ContactUs from './components/contact-us';
import FAQ from './components/faq';
import OrderStatus from './components/order-status';
import NoMatch from './components/page-not-found';
import Pay from './components/pay';
import ShirtPage from './components/shirt-page';

class AppRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={ArtPage} />
          <Route path="/sofloo" component={ShirtPage} />
          <Route path="/faq" component={FAQ} />
          <Route path="/checkout" component={Pay} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/order" component={OrderStatus} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
