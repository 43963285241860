import React from 'react';

import './loader.css';

export default function Loader() {
  return (
    <div className="sofloo-loader-container">
      <div className="sofloo-loader">
        <div className="sofloo-loader-outer-box"/>
        <div className="sofloo-loader-inner-box"/>
      </div>
      <div className="sofloo-loader">
        <div className="sofloo-loader-outer-box"/>
        <div className="sofloo-loader-inner-box"/>
      </div>
      <div className="sofloo-loader">
        <div className="sofloo-loader-outer-box"/>
        <div className="sofloo-loader-inner-box"/>
      </div>
    </div>
  );
}
