import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ACTIONS } from '../../store';
import { PAYMENT_WINDOWS } from '../../constants';
import WebService from '../../web-service';

import './confirmation.css';

import Loader from '../loader';

class Confirmation extends Component {
  static propTypes = {
    backToCustomerInformationPage: PropTypes.func.isRequired,
    clearOrders: PropTypes.func.isRequired,
    customerInformation: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    resetPaymentWindow: PropTypes.func.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    setMakingOrder: PropTypes.func.isRequired,
    soflooSeed: PropTypes.number.isRequired,
    soflooVersion: PropTypes.string.isRequired,
    stripeToken: PropTypes.string.isRequired,
    totalPrice: PropTypes.number.isRequired
  }

  state = {
    orderError: false,
    orderConfirmed: false,
    possiblyCharged: false,
    trackingUrl: ''
  };

  componentDidMount() {
    this.makeTShirtOrder();
  }

  makeAnotherSofloo = () => {
    window.location = '/';
  }

  makeTShirtOrder = async () => {
    const orderId = Date.now(); // Kind of unnecesary extra check we run to counter duplicate orders.

    this.props.setMakingOrder(true);

    try {
      const customerId = await WebService.makeOrder(
        this.props.orders,
        this.props.customerInformation,
        this.props.selectedShippingOption,
        this.props.coupon,
        this.props.totalPrice,
        this.props.soflooSeed,
        this.props.soflooVersion,
        orderId,
        this.props.stripeToken
      );
      this.props.setMakingOrder(false);

      const baseURL = `${window.location.origin}/`;
      const trackingUrl = `${baseURL}order?id=${customerId}`;

      // Clear the orders to ensure the user doens't try to order the same thing again.
      this.props.clearOrders();

      this.setState({
        orderConfirmed: true,
        orderError: false,
        possiblyCharged: false,
        trackingUrl
      });
    } catch (e) {
      this.props.setMakingOrder(false);
      console.log('Error w/ checkout', e);
      this.setState({
        orderConfirmed: true,
        orderError: e.message,
        possiblyCharged: true
      });
    }
  }

  render() {
    const { orderError, orderConfirmed, possiblyCharged, trackingUrl } = this.state;
    const { backToCustomerInformationPage, customerInformation } = this.props;

    return (
      <div>
        {orderConfirmed && orderError && !possiblyCharged &&(
          <div
            className="pay-back-button"
            onClick={backToCustomerInformationPage}
          >
            <span className="pay-back-button-icon">&lsaquo;</span> customer information
          </div>
        )}
        <div className="pay-title">
          sofloo
        </div>
        {!orderConfirmed && (
          <div className="confirmation-confirmed-message">
            <div className="confirmation-loading">
              Getting your shirt...
            </div>
            <Loader />
          </div>
        )}
        {orderConfirmed && orderError && (
          <div className="confirmation-confirmed-message">
            <div className="confirmation-message-inner">
              Uh oh. Something went wrong performing the purchase request.
            </div>
            <div className="confirmation-message-inner confirmation-error-message">
              {orderError}
            </div>
          </div>
        )}
        {orderConfirmed && !orderError && (
          <React.Fragment>
            <div
              className="confirmation-confirmed-message"
            >
              <div className="confirmation-message-inner">
                Boom.
              </div>
              <div className="confirmation-message-inner">
                Your new shirt is on its way to the printing press and then it'll be shipped to your door.
                A confirmation email with a tracking link is being sent to {customerInformation.email}
              </div>
              <div className="confirmation-message-inner">
                Thank you so much for buying this shirt and supporting me as an artist - it means a tremendous amount. Please tell your friends and feel free to share this site around - no one is going to get the same shirt as you.
              </div>
              <div className="confirmation-message-inner">
                You can follow your order at {(
                  <a
                    className="confirmation-track-url"
                    href={trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>{trackingUrl}</strong>
                  </a>
                )}
              </div>
            </div>
            <div
              className="confirmation-make-another"
              onClick={this.makeAnotherSofloo}
            >
              create another one :D
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  coupon: state.coupon,
  customerInformation: state.customerInformation,
  orders: state.orders,
  selectedShippingOption: state.selectedShippingOption,
  soflooSeed: state.soflooSeed,
  soflooVersion: state.soflooVersion,
  stripeToken: state.stripeToken,
  totalPrice: state.totalPrice
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    backToCustomerInformationPage: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.CUSTOMER_INFORMATION
    }),
    clearOrders: () => ({
      type: ACTIONS.CLEAR_ORDERS
    }),
    resetPaymentWindow: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.BUILD_ORDER
    }),
    setMakingOrder: makingOrder => ({
      type: ACTIONS.SET_MAKING_ORDER,
      makingOrder
    })
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);
