import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './art-page.css';

import Generative from '../generative';
import Header from '../header';

import ACTIONS from '../../store/actions';
import { getSoflooUrl } from '../../util/url';

class ArtPage extends Component {
  static propTypes = {
    soflooSeed: PropTypes.number,
    soflooVersion: PropTypes.string
  };

  purchaseShirtClicked = (soflooRef, soflooSeed, soflooVersion) => {
    this.props.setSoflooAttributes(soflooSeed, soflooVersion, soflooRef);

    this.props.history.push(getSoflooUrl(soflooSeed, soflooVersion));
  }

  componentDidMount() {
    document.title = `Sofloo`;
  }
  
  render() {
    const {
      soflooSeed,
      soflooVersion
    } = this.props;

    return (
      <div className="home">
        <Header />
        <Generative
          purchaseShirtClicked={this.purchaseShirtClicked}
          soflooSeed={soflooSeed}
          soflooVersion={soflooVersion}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  soflooSeed: state.soflooSeed,
  soflooVersion: state.soflooVersion
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setSoflooAttributes: (soflooSeed, soflooVersion, soflooRef) => ({
      type: ACTIONS.SET_SOFLOO,
      soflooSeed,
      soflooVersion,
      soflooRef
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtPage);
