import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ACTIONS } from '../../store';
import { PAYMENT_WINDOWS } from '../../constants';
import WebService from '../../web-service';

import './shipping.css';

import Loader from '../loader';

class Shipping extends Component {
  static propTypes = {
    backToCustomerInformation: PropTypes.func.isRequired,
    continueToCheckout: PropTypes.func.isRequired,
    customerInformation: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    selectShippingOrder: PropTypes.func.isRequired,
    setShippingOptions: PropTypes.func.isRequired,
    shippingOptions: PropTypes.array.isRequired,
    shippingOptionsAvailable: PropTypes.bool.isRequired,
    shippingOptionsLoaded: PropTypes.bool.isRequired
  }

  state = {
    errorContinuingWithOrder: null
  }

  componentWillMount = () => {
    if (!this.props.shippingOptionsLoaded) {
      this.loadShippingOptions();
    }
  }

  componentWillUnmount = () => {
    this.mounted = false;
  }

  loadShippingOptions = async () => {
    this.mostRecentShippingOptionsVersion++;
    const currentRequest = this.mostRecentShippingOptionsVersion;

    this.setState({
      errorContinuingWithOrder: null,
      shippingOptionsLoaded: false
    });

    try {
      const shippingOptions = await WebService.getShippingOptions(this.props.customerInformation, this.props.orders);

      if (currentRequest !== this.mostRecentShippingOptionsVersion) {
        console.log('No longer active request.')
        // If we're no longer the current request just do nothing with the information.
        return;
      }
  
      this.setState({
        errorContinuingWithOrder: null
      });
  
      this.props.setShippingOptions(shippingOptions);
    } catch(e) {
      if (currentRequest === this.mostRecentShippingOptionsVersion) {
        this.setState({
          errorContinuingWithOrder: e.message
        });
        return false;
      }
    }
  }

  validate = () => {
    if (!this.props.shippingOptionsLoaded) {
      this.setState({
        errorContinuingWithOrder: 'Please wait until your shipping options have loaded.'
      });
      return false;
    }

    if (!this.props.shippingOptionsAvailable) {
      this.setState({
        errorContinuingWithOrder: 'Sorry you cannot proceed until we\'ve got a shipping method for you.'
      });
      return false;
    }

    if (!this.props.selectedShippingOption) {
      this.setState({
        errorContinuingWithOrder: 'Please select a shipping method.'
      });
      return false;
    }
    
    return true;
  }

  checkoutClicked = () => {
    const valid = this.validate();
    
    if (valid) {
      this.props.continueToCheckout();
    }
  }

  // If a user goes back to customer information and forward quickly we might be making two data
  // queries at once. This safe guards so that only the most recent request has the ability
  // to change the state;
  mostRecentShippingOptionsVersion = 0;

  render() {
    const {
      backToCustomerInformation,
      selectShippingOrder,
      selectedShippingOption,
      shippingOptionsAvailable,
      shippingOptions,
      shippingOptionsLoaded
    } = this.props;

    const { errorContinuingWithOrder } = this.state;

    return (
      <div className="shipping">
        <div
          className="pay-back-button"
          onClick={backToCustomerInformation}
        >
          <span className="pay-back-button-icon">&lsaquo;</span> edit info
        </div>
        <div className="pay-title">
          sofloo shipping
        </div>
        {!shippingOptionsLoaded && !errorContinuingWithOrder && (
          <div className="shipping-options-loading">
            <div className="shipping-options-loading-message">
              Loading shipping options...
            </div>
            <Loader />
          </div>
        )}
        {shippingOptionsLoaded && !shippingOptionsAvailable && (
          <div>
            Sorry! We can't ship to you :'(
            <br />
            Please try another address.
          </div>
        )}
        {shippingOptionsLoaded && shippingOptionsAvailable && !errorContinuingWithOrder && (
          <React.Fragment>
            <div className="shipping-title">
              Choose your shipping option:
            </div>
            {shippingOptions.map((option, index) => (
              <div
                className="shipping-option-container"
                key={`${index}_${option.id}`}
              >
                <label>
                  <input
                    className="shipping-option-input"
                    key={option.id}
                    name="shippingOption"
                    placeholder="shippingOption"
                    type="radio"
                    checked={selectedShippingOption.id === option.id}
                    onChange={() => selectShippingOrder(option)}
                  />
                  <div className="shipping-option-label">
                    <div className="shipping-option-name">
                      {option.name}
                    </div>
                    <div className="shipping-option-rate">
                      {option.rate} {option.currency}
                    </div>
                  </div>
                </label>                
              </div>
            ))}
            <div className="shipping-checkout-button-container">
              <div
                className="sofloo-button"
                onClick={this.checkoutClicked}
              >
                continue
              </div>
            </div>
          </React.Fragment>
        )}
        {errorContinuingWithOrder && (
          <div className="pay-error-message">
            {errorContinuingWithOrder}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerInformation: state.customerInformation,
  orders: state.orders,
  selectedShippingOption: state.selectedShippingOption,
  shippingOptions: state.shippingOptions,
  shippingOptionsAvailable: state.shippingOptionsAvailable,
  shippingOptionsLoaded: state.shippingOptionsLoaded,
  subtotal: state.subtotal
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    backToCustomerInformation: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.CUSTOMER_INFORMATION
    }),
    continueToCheckout: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.CHECKOUT
    }),
    selectShippingOrder: newShippingOption => ({
      type: ACTIONS.SELECT_SHIPPING_OPTION,
      newShippingOption
    }),
    setShippingOptions: newShippingOptions => ({
      type: ACTIONS.SET_SHIPPING_OPTIONS,
      newShippingOptions
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shipping);
