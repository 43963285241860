import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import './shirt-preview.css';

import Loader from '../loader';

import { SHIRT_COLORS } from '../../constants/constants';

const maxPreviewWidth = 1455; // 1000
const maxPreviewHeight = 2521;
const maxPreviewWHRatio = maxPreviewWidth / maxPreviewHeight;
const maxPreviewHWRatio = maxPreviewHeight / maxPreviewWidth;

const artShirtWidthRatio = 900 / maxPreviewWidth;
const artRatio = 16 / 12;
// const artTopPositionRatio = 12 / 32;
const artTopPositionRatio = 19 / 32;

function getMockupImageUrlForColor(color) {
  return color === SHIRT_COLORS.white ? 'shirt_fullsize_labeled.png' : 'shirtdark_fullsize_labeled.png';// 'shirt-white.png' : 'shirt-black.png';
}

class ShirtPreview extends Component {
  static propTypes = {
    failedToGenerateMockups: PropTypes.bool.isRequired,
    haveSoflooGenerated: PropTypes.bool.isRequired,
    haveSoflooRenders: PropTypes.bool.isRequired,
    mockupCreationIsTakingALongTime: PropTypes.bool,
    recreateMockups: PropTypes.func,
    shirtColor: PropTypes.string.isRequired,
    soflooRenderUrl: PropTypes.string.isRequired
  };

  static defaultProps = {
    mockupCreationIsTakingALongTime: false
  }

  state = {
    artHeight: 1,
    artImageLoaded: false,
    artWidth: 1
  }

  updateDimensions = () => {
    if (!this.shirtPreviewRef || !this.shirtPreviewRef.current) {
      return;
    }

    let width = this.shirtPreviewRef.current.offsetWidth;
    let height = this.shirtPreviewRef.current.offsetHeight;

    if (width > maxPreviewWidth && height < maxPreviewHeight) {
      width = height * maxPreviewWHRatio;
    } else if (height > maxPreviewHeight && width < maxPreviewWidth) {
      height = maxPreviewWidth * maxPreviewHWRatio;
    } else if (width > maxPreviewWidth) {
      width = maxPreviewWidth;
      height = maxPreviewHeight;
    }

    const artWidth = width * artShirtWidthRatio;
    const artHeight = artWidth * artRatio;

    this.setState({
      artWidth: artWidth,
      artHeight: artHeight
    });
  }

  componentDidMount() {
    this.mounted = true;
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    this.mounted = false;
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.soflooRenderUrl !== this.props.soflooRenderUrl) {
      this.setState({
        artImageLoaded: false
      });
    }
  }

  artImageOnLoad = () => {
    this.setState({
      artImageLoaded: true
    });
  }

  shirtPreviewRef = React.createRef()

  render() {
    const {
      failedToGenerateMockups,
      haveSoflooGenerated,
      haveSoflooRenders,
      mockupCreationIsTakingALongTime,
      soflooRenderUrl,
      recreateMockups,
      shirtColor
    } = this.props;

    const {
      artHeight,
      artImageLoaded,
      artWidth
    } = this.state;

    return (
      <div className="shirt">
        <div className="shirt-preview-container">
          {haveSoflooGenerated && !haveSoflooRenders && failedToGenerateMockups && (
            <div className="shirt-generating-mockups-failed">
              <div className="shirt-generating-mockups-failed-message">
                :/ Unable to create mockups
              </div>
              <div
                className="shirt-generate-mockups-again"
                onClick={recreateMockups}
              >
                Try again :D
              </div>
            </div>
          )}
          {!haveSoflooGenerated && (
            <div className="shirt-generating-art">
              <div>
                Building art...
              </div>
            </div>
          )}
          {haveSoflooGenerated && !haveSoflooRenders && !failedToGenerateMockups && (
            <div className="shirt-generating-mockups">
              <div>
                Creating t shirt mockup...
              </div>
              {mockupCreationIsTakingALongTime && (
                <div>
                  Building an image from the artwork.
                </div>
              )}
              <Loader />
            </div>
          )}
          {haveSoflooGenerated && haveSoflooRenders && (
            <div className="shirt-preview-shirt-container">
              <img
                alt="Shirt mockup"
                className="shirt-preview-shirt"
                id="js-shirt-preview"
                onLoad={this.updateDimensions}
                ref={this.shirtPreviewRef}
                src={getMockupImageUrlForColor(shirtColor)}
              />
              <div className="shirt-sofloo-container">
                {!artImageLoaded && (
                  <div className="shirt-sofloo-art-loading">Loading...</div>
                )}
                <img
                  className="shirt-sofloo-art-image"
                  src={soflooRenderUrl}
                  alt="Art"
                  onLoad={this.artImageOnLoad}
                  style={{
                    marginTop: artHeight * artTopPositionRatio,
                    opacity: artImageLoaded ? 1 : 0.1,
                    width: artWidth,
                    height: artHeight
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = null/*(state, ownProps) => ({
  haveSoflooGenerated: state.haveSoflooGenerated,
  haveSoflooRenders: !!state.soflooRenders,
  soflooRenderUrl: state.soflooRenderUrl,
  shirtColor: state.shirtColor
})*/;

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShirtPreview);

