import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ACTIONS } from '../../store';
import { PAYMENT_WINDOWS, SHIRT_VARIANTS } from '../../constants';
import { getShirtSizeLabelForValue } from '../../util/orders';
import { formatMoneyAsNiceString } from '../../util/money';

import './checkout.css';

class Checkout extends Component {
  static propTypes = {
    backToShipping: PropTypes.func.isRequired,
    checkoutAndDoThePurchase: PropTypes.func.isRequired,
    customerInformation: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    subtotal: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired
  }

  render() {
    const {
      orders,
      customerInformation,
      selectedShippingOption,
      subtotal,
      totalPrice
    } = this.props;
    const {
      email,
        
      shippingName,
      shippingAddress1,
      shippingAddress2,
      shippingZip,
      shippingCity,
      shippingCountry,
      shippingRegion
    } = customerInformation;

    return (
      <div>
        <div
          className="pay-back-button"
          onClick={() => this.props.backToShipping()}
        >
          <span className="pay-back-button-icon">&lsaquo;</span> back to shipping
        </div>
        <div className="pay-title">
          sofloo order overview
        </div>
        <div className="row">
          <div className="col-md-7">
            <div className="checkout-section-order-info">
              <div className="row">
                <div className="col-3 purchase-info-title">
                  quantity
                </div>
                <div className="col-3 purchase-info-title">
                  size
                </div>
                <div className="col-3 purchase-info-title">
                  unit price
                </div>
                <div className="col-3 purchase-info-title">
                  price
                </div>
              </div>
              {orders.map((order, index) => (
                <div
                  className="purchase-info-container"
                  key={`${index}_${order.quantity}_${order.variantId}`}
                >
                  <div className="row">
                    <div className="col-3 purchase-info-item">
                      {order.quantity}
                    </div>
                    <div className="col-3 purchase-info-item">
                      {getShirtSizeLabelForValue(SHIRT_VARIANTS[order.variantId].size)}
                    </div>
                    <div className="col-3 purchase-info-item">
                      {order.unitPrice}
                    </div>
                    <div className="col-3 purchase-info-item">
                      {order.unitPrice * order.quantity}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="checkout-section">
              <div className="checkout-section-title">
                Shipping info
              </div>
              <div className="checkout-info-item">
                {email}
              </div>
              <div className="checkout-info-item">
                {shippingName}
              </div>
              <div className="checkout-info-item">
                {shippingAddress1}
              </div>
              <div className="checkout-info-item">
                {shippingAddress2}
              </div>
              <div className="checkout-info-item">
                {shippingCity}
              </div>
              <div className="checkout-info-item">
                {shippingZip}
              </div>
              <div className="checkout-info-item">
                {shippingCountry}
              </div>
              <div className="checkout-info-item">
                {shippingRegion}
              </div>
            </div>
            <div className="checkout-section">
              <div className="checkout-section-title">
                Shipping method
              </div>
              <div className="checkout-info-item">
                {selectedShippingOption.name}
              </div>
              <div className="checkout-info-item">
                {selectedShippingOption.rate} {selectedShippingOption.currency}
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="checkout-prices">
              <div className="row">
                <div className="col-6 checkout-price-item">
                  Subtotal
                </div>
                <div className="col-6 checkout-price-item">
                  $ {formatMoneyAsNiceString(subtotal)}
                </div>
              </div>
              <div className="row">
                <div className="col-6 checkout-price-item">
                  Shipping
                </div>
                <div className="col-6 checkout-price-item">
                  $ {formatMoneyAsNiceString(selectedShippingOption.rate)}
                </div>
              </div>
              <div className="row">
                <div className="col-6 checkout-price-item">
                  Total
                </div>
                <div className="col-6 checkout-price-item">
                  $ {formatMoneyAsNiceString(totalPrice)}
                </div>
              </div>
              <div className="checkout-button-container">
                <div
                  className="sofloo-button"
                  onClick={this.props.checkoutAndDoThePurchase}
                >
                  checkout
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerInformation: state.customerInformation,
  orders: state.orders,
  selectedShippingOption: state.selectedShippingOption,
  subtotal: state.subtotal,
  totalPrice: state.totalPrice
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    backToShipping: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.SHIPPING
    }),
    checkoutAndDoThePurchase: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.CONFIRMATION
    }),
    continueToCheckout: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.CHECKOUT
    }),
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
