import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './share.css';

import CloseButton from '../close-button';

class Share extends Component {
  static propTypes = {
    hideShareWindow: PropTypes.func.isRequired,
    shareUrl: PropTypes.string.isRequired
  }
  
  copyClicked = () => {
    // Create and remove an element used to copy the url to the user's clipboard.
    const el = document.createElement('textarea');
    el.value = this.props.shareUrl;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';                 
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =            
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    this.setState({
      copied: true
    });
  }

  state = {
    copied: false
  }

  render() {
    const { copied } = this.state;
    const { shareUrl } = this.props;
    
    return (
      <div className="share-container">
        <div className="share-background" onClick={this.props.hideShareWindow} />
        <div className="share">
        <CloseButton onClick={this.props.hideShareWindow}/>
          <div className="share-title">
            thanks for sharing :)
          </div>
          <div className="share-url-container">
            <a
              className="share-url"
              href={shareUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {shareUrl}
            </a>
            <div
              className="sofloo-button share-button"
              onClick={this.copyClicked}
            >{copied ? 'copied!' : 'copy'}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Share;
