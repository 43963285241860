export default {
  ADD_TO_ORDERS: 'sofloo/ADD_TO_ORDERS',
  CLEAR_ORDERS: 'sofloo/CLEAR_ORDERS',
  SELECT_SHIPPING_OPTION: 'sofloo/SELECT_SHIPPING_OPTION',
  SET_COUPON: 'sofloo/SET_COUPON',
  SET_CONTACT_WINDOW: 'sofloo/SET_CONTACT_WINDOW',
  SET_HAVE_SOFLOO_GENERATED: 'sofloo/SET_HAVE_SOFLOO_GENERATED',
  SET_MAKING_ORDER: 'sofloo/SET_MAKING_ORDER',
  SET_PAYMENT_WINDOW: 'sofloo/SET_PAYMENT_WINDOW',
  SET_SHIPPING_OPTIONS: 'sofloo/SET_SHIPPING_OPTIONS',
  SET_SHIRT_PLACEMENT: 'sofloo/SET_SHIRT_PLACEMENT',
  SET_SHIRT_VARIANT: 'sofloo/SET_SHIRT_VARIANT',
  SET_SOFLOO: 'sofloo/SET_SOFLOO',
  SET_SOFLOO_RENDERS: 'sofloo/SET_SOFLOO_RENDERS',
  SET_STRIPE_TOKEN: 'sofloo/SET_STRIPE_TOKEN',
  UPDATE_CUSTOMER_INFORMATION: 'sofloo/UPDATE_CUSTOMER_INFORMATION',
  UPDATE_ORDERS: 'sofloo/UPDATE_ORDERS'
};
