import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './faq.css';

import Header from '../header';

class FAQ extends Component {
  componentDidMount() {
    document.title = `Sofloo - FAQ`;
  }

  render() {
    return (
      <div className="faq">
        <Header />

        <div className="faq-container">
          <h1>
            faq
          </h1>
          <div className="faq-qa-container">
            <div className="faq-question">
              what are the t-shirts made out of?
            </div>
            <div className="faq-answer">
              100% ring spun hand drawn cotton. we get our t-shirt blanks from next level. made in central america.
            </div>
          </div>
          <div className="faq-qa-container">
            <div className="faq-question">
              how long does t-shirt fulfillment take?
            </div>
            <div className="faq-answer">
              t-shirt fulfillment typically takes 2-7 days.
            </div>
          </div>
          <div className="faq-qa-container">
            <div className="faq-question">
              how can I contact sofloo?
            </div>
            <div className="faq-answer">
              <Link
                className="faq-contact-link"
                to="/contact"
                target="_blank"
                rel="noopener noreferrer"
              >contact us</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
