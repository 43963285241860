import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';

import 'babel-polyfill';

import Router from '../../router';

import './app.css';
import './grid.css';

class App extends Component {
  render() {
    return (
      <div className="app">
        <StripeProvider apiKey="pk_live_x6gU8gvc6QtNBkvvD4zMC75T">
          <Router />
        </StripeProvider>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  showContactUsWindow: state.showContactUsWindow
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
