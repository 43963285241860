const {
  SHIRT_SIZE_OPTIONS,
  SHIRT_VARIANTS,
  SHIRT_UNIT_PRICE,
  VARIANT_IDS
} = require('../constants');

module.exports.getShirtLabelForSize = function(label) {
  return SHIRT_SIZE_OPTIONS[label];
}

module.exports.createNewOrder = function(variantId, shirtPlacement, soflooRenderUrl, soflooSeed, soflooVersion) {
  return {
    soflooRenderUrl,
    quantity: 1,
    shirtPlacement,
    soflooSeed,
    soflooVersion,
    unitPrice: SHIRT_UNIT_PRICE,
    variantId
  };
}

module.exports.getTotalQuantity = function getTotalQuantity(orders) {
  let totalQuantity = 0;
  for (let i = 0; i < orders.length; i++) {
    totalQuantity += Number(orders[i].quantity);
  }

  return totalQuantity;
}

module.exports.getShirtSizeLabelForValue = function(shirtSizeValue) {
  for(let i = 0; i < SHIRT_SIZE_OPTIONS.length; i++) {
    if (SHIRT_SIZE_OPTIONS[i].value === shirtSizeValue) {
      return SHIRT_SIZE_OPTIONS[i].label;
    }
  }
}

module.exports.getVariantForColorAndSize = function(color, size) {
  let variantId = null;

  for(let i = 0; i < VARIANT_IDS.length; i++) {
    if (color === SHIRT_VARIANTS[VARIANT_IDS[i]].color && size === SHIRT_VARIANTS[VARIANT_IDS[i]].size) {
      variantId = VARIANT_IDS[i];
    }
  }

  return variantId;
}

const getSubtotalFromOrders = function(orders, coupon) {
  let subtotal = 0;
  let freeShirts = 0;

  if (coupon && Number(coupon.freeShirts) > 0) {
    freeShirts = Number(coupon.freeShirts);
  }

  for (let i = 0; i < orders.length; i++) {
    if (freeShirts > 0) {
      if (Number(orders[i].quantity) > freeShirts) {
        freeShirts = 0;
        subtotal += (Number(orders[i].quantity) - freeShirts) * SHIRT_UNIT_PRICE;
      } else {
        freeShirts -= Number(orders[i].quantity);
      }
    } else {
      subtotal += Number(orders[i].quantity) * SHIRT_UNIT_PRICE;
    }
  }

  if (coupon && Number(coupon.discountPercentage) > 0) {
    subtotal *= 1 - Number(coupon.discountPercentage);
  }

  return subtotal;
}
module.exports.getSubtotalFromOrders = getSubtotalFromOrders;

module.exports.getTotalPrice = function(orders, shippingRate, coupon) {
  let shippingCost = shippingRate ? Number(shippingRate) : 0;
  if (coupon && coupon.freeShipping) {
    shippingCost = 0;
  }
  
  return shippingCost + getSubtotalFromOrders(orders, coupon);
}

module.exports.getDescriptionForStatus = function(status) {
  switch(status) {
    case 'draft':
      return 'Your order is currently being submitted into the system.';
    case 'failed':
      return 'There was an error creating your order with our manufacturer. We\'ll be looking into it. Feel free to reach out to support@sofloo.com';
    case 'pending':
      return 'Your order has been created and we are working with our manufacturer. It\'s on it way to be printed. Please check back for shipping information later.';
    case 'canceled':
      return 'Your order was canceled in our system. If you believe this is an error please reach out to us at support@sofloo.com';
    case 'onhold':
      return 'We\'ve encountered a problem with your order during the manufacturing process, we\'re fixing the issue now. Please check back later for more information. Feel free to reach out to support@sofloo.com';
    case 'inprocess':
      return 'Your order is currently in progress, we\'re printing it right now. Please check back later for shipping information.';
    case 'partial':
      return 'Part of your order has already shipped, and we\'re working on the rest right now. Please check back later for more shipping information.';
    case 'fulfilled':
      return 'Your order has been shipped! It\'s on its way. :D';
    case 'archived':
      return 'Your order is in the archives. If you believe this is an error please reach out to us at support@sofloo.com';
    default:
      console.log('Unknown status?', status);
      return 'Your order is currently be submitted into the system.';
  }
}
