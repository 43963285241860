import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './close-button.css';

class CloseButton extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
  }

  static defaultProps = {
    disabled: false
  }

  render() {
    return (
      <div
        className={`close-button${this.props.disabled ? ' close-button-disabled' : ''}`}
        onClick={() => {return !this.props.disabled ? this.props.onClick() : null;}}
      >x</div>
    );
  }
}

export default CloseButton;
