import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { getTotalQuantity } from '../../util/orders';

class ShoppingCart extends Component {
  static propTypes = {
    hideIfEmpty: PropTypes.bool.isRequired,
    orders: PropTypes.array.isRequired
  };

  render() {
    const { hideIfEmpty, orders } = this.props;

    const ordersSum = getTotalQuantity(orders);

    if (ordersSum < 1 && hideIfEmpty) {
      return null
    }
    
    return (
      <Link className="sc-cart-button" to="/checkout">
        <svg className="sc-cart-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path className="sc-cart-icon" d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z" /></svg>
        <div className="red-indicator">{ordersSum}</div>
      </Link>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  orders: state.orders
});

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShoppingCart);
