import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { bindActionCreators } from 'redux';

import Input from '../inputs';

import ACTIONS from '../../store/actions';
import { countries, PAYMENT_WINDOWS } from '../../constants';
import { isStringAndIsInBounds } from '../../util/type';

import './customer-information.css';
import './stripe-styling.css';

class CustomerInformation extends Component {
  static propTypes = {
    backToEditOrder: PropTypes.func.isRequired,
    continueToShippingOptions: PropTypes.func.isRequired,
    subtotal: PropTypes.number.isRequired,
    updateStripeToken: PropTypes.func.isRequired
  }

  state = {
    errorContinuingWithOrder: null,
    loadingStripe: false
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      [name]: value
    });
  }

  handleInputUpdate = (name, event) => {
    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      [name]: event.target.value
    });
  }

  selectShippingCountry = newCountry => {
    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      shippingCountry: newCountry.value,
      shippingCountrySelected: newCountry
    });
  }

  selectShippingRegion = newRegion => {
    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      shippingRegion: newRegion.value,
      shippingRegionSelected: newRegion
    });
  }

  // Returns true if the values in the form are valid.
  validate = () => {
    const {
      email,
        
      shippingName,
      shippingAddress1,
      shippingZip,
      shippingCity,
      shippingCountry,
      shippingCountrySelected,
      shippingRegion,
    } = this.props;

    if (!email) {
      this.setState({
        errorContinuingWithOrder: 'No email supplied.'
      });
      return false;
    }

    if (!isStringAndIsInBounds(shippingName, 1, 200)) {
      this.setState({
        errorContinuingWithOrder: 'No shipping name supplied.'
      });
      return false;
    } else if (!isStringAndIsInBounds(shippingAddress1, 1, 400)) {
      this.setState({
        errorContinuingWithOrder: 'No shipping address supplied.'
      });
      return false;
    } else if (!isStringAndIsInBounds(shippingZip, 1, 200)) {
      this.setState({
        errorContinuingWithOrder: 'No shipping zip supplied.'
      });
      return false;
    } else if (!isStringAndIsInBounds(shippingCity, 1, 200)) {
      this.setState({
        errorContinuingWithOrder: 'No shipping city supplied.'
      });
      return false;
    } else if (!isStringAndIsInBounds(shippingCountry, 1, 200)) {
      this.setState({
        errorContinuingWithOrder: 'No shipping country supplied.'
      });
      return false;
    } else if (shippingCountrySelected.states && shippingCountrySelected.states && !isStringAndIsInBounds(shippingRegion, 1, 200)) {
      this.setState({
        errorContinuingWithOrder: 'No shipping region supplied.'
      });
      return false;
    }

    return true;
  }

  continueClicked = async () => {
    const valid = this.validate();

    if (!valid || this.state.loadingStripe) {
      return;
    }

    this.setState({
      loadingStripe: true
    });

    // https://stripe.com/docs/testing#cards
    // 4242424242424242
    // 4242 4242 4242 4242

    // To create a disputed transaction:
    // 4000 0000 0000 0259

    const { token, error } = await this.props.stripe.createToken();

    if (error) {
      console.log('stripe error', error);

      this.setState({
        errorContinuingWithOrder: error.message,
        loadingStripe: false
      });
      return;
    }

    if (!token || !token.id) {
      this.setState({
        errorContinuingWithOrder: 'Unable to verify your card. Please re-enter or refresh and try again.',
        loadingStripe: false
      });
      return;
    }

    console.log('Got stripe token', token);
    this.props.updateStripeToken(`${token.id}`);

    this.setState({
      loadingStripe: false
    });

    this.props.continueToShippingOptions();
  }

  render() {
    const {
      errorContinuingWithOrder,
      loadingStripe
    } = this.state;

    const {    
      email,
        
      shippingName,
      shippingAddress1,
      shippingAddress2,
      shippingZip,
      shippingCity,
      shippingCountrySelected,
      shippingRegionSelected
    } = this.props;

    return (
      <div className="ci">
        <div
          className="pay-back-button"
          onClick={this.props.backToEditOrder}
        >
          <span className="pay-back-button-icon">&lsaquo;</span> edit your order
        </div>
        <div className="pay-title">
          sofloo billing &amp; shipping information
        </div>
        <form className="ci-container">
          <div className="ci-section-title">
            shipping info
          </div>
          <Input
            id="shippingName"
            label="Name"
            onChange={event => this.handleInputUpdate('shippingName', event)}
            value={shippingName}
            name="name"
            autocomplete="name"
          />
          <Input
            id="email"
            label="Email"
            onChange={event => this.handleInputUpdate('email', event)}
            value={email}
          />
          <Input
            id="shippingAddress1"
            label="Address"
            onChange={event => this.handleInputUpdate('shippingAddress1', event)}
            value={shippingAddress1}
          />
          <Input
            id="shippingAddress2"
            label="Apartment, suite, unit, etc."
            onChange={event => this.handleInputUpdate('shippingAddress2', event)}
            value={shippingAddress2}
          />
          <div className="row">
            <div className="col-8">
              <Input
                id="shippingCity"
                label="City"
                onChange={event => this.handleInputUpdate('shippingCity', event)}
                value={shippingCity}
              />
            </div>
            <div className="col-4 ci-zip">
              <Input
                id="shippingZip"
                label="Zip"
                onChange={event => this.handleInputUpdate('shippingZip', event)}
                value={shippingZip}
              />
            </div>
          </div>
          <div className="ci-input-item">
            <div className="ci-item-label">
              country &amp; region
            </div>
            <Select
              value={shippingCountrySelected}
              onChange={this.selectShippingCountry}
              options={countries}
              name="country"
              menuPlacement="auto"
            />
            {shippingCountrySelected && shippingCountrySelected.states && shippingCountrySelected.states.length > 0 && (
              <div className="ci-region-container">
                <Select
                  value={shippingRegionSelected}
                  onChange={this.selectShippingRegion}
                  options={shippingCountrySelected.states}
                  menuPlacement="auto"
                />
              </div>
            )}
          </div>

          <div className="ci-section">
            <div className="ci-section-title">
              payment info
            </div>
            <CardElement
              style={{
                base: {
                  fontSize: '16px'
                }
              }}
            />
          </div>
          {errorContinuingWithOrder && (
            <div className="pay-error-message">
              {errorContinuingWithOrder}
            </div>
          )}
          <div className="ci-button-container">
            <div
              className="sofloo-button"
              onClick={this.continueClicked}
            >
              {loadingStripe ? 'working...' : 'shipping options'}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.customerInformation,
  subtotal: state.subtotal
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    backToEditOrder: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.BUILD_ORDER
    }),
    continueToShippingOptions: () => ({
      type: ACTIONS.SET_PAYMENT_WINDOW,
      newPaymentWindow: PAYMENT_WINDOWS.SHIPPING
    }),
    updateCustomerInformation: customerInformationUpdate => ({
      type: ACTIONS.UPDATE_CUSTOMER_INFORMATION,
      customerInformationUpdate
    }),
    updateStripeToken: stripeToken => ({
      type: ACTIONS.SET_STRIPE_TOKEN,
      stripeToken
    })
  }, dispatch);
}

const StripeInjectedCustomerInformation = injectStripe(CustomerInformation);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeInjectedCustomerInformation);
