module.exports.formatMoneyAsNiceString = function(money) {
  let moneyString = `${Math.floor(Number(money) * 100) / 100}`;
  if (moneyString.indexOf('.') === -1) {
    moneyString += '.00';
  } else if (moneyString.indexOf('.') === moneyString.length - 2) {
    moneyString += '0';
  }

  return moneyString;
}
