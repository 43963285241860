import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isStringAndIsInBounds } from '../../util/type';

import './coupon.css';

import ACTIONS from '../../store/actions';

import Input from '../inputs';

class Coupon extends Component {
  state = {
    hidden: true
  }

  onClickApply = async () => {
    await this.validateCoupon();
  }

  validateCoupon = async () => {
    const {
      couponCode,
      fetching
    } = this.props.coupon;

    if (fetching) {
      return;
    }

    this.props.setCoupon({
      applied: false,
      fetching: true,
      couponMessage: '',
      errorApplying: false,
      freeShirts: 0,
      discountPercentage: 0,
      freeShipping: false
    });

    this.couponApplyVersion++;
    const currentVersion = this.couponApplyVersion;

    if (!isStringAndIsInBounds(couponCode, 2, 120)) {
      this.props.setCoupon({
        errorApplying: 'Please enter a valid coupon code.',
        fetching: false
      });
      return;
    }

    try {
      const response = await fetch('/coupon', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          couponCode
        })
      });

      console.log('response', response);

      if (!response.ok) {
        if (this.couponApplyVersion === currentVersion) {
          const text = await response.text();

          this.props.setCoupon({
            errorApplying: text,
            fetching: false
          });
        }
        return;
      }

      const jsonResponse = await response.json();

      if (this.couponApplyVersion === currentVersion) {
        this.props.setCoupon({
          ...jsonResponse,
          applied: true,
          errorApplying: false,
          fetching: false
        });
        
        return;
      }
    } catch (e) {
      if (this.couponApplyVersion === currentVersion) {
        this.props.setCoupon({
          errorApplying: 'Unable to communicate with server. Please refresh and try again.',
          fetching: false
        });
      }
    }
  }

  handleInputChange = async (name, event) => {
    this.props.setCoupon({
      errorApplying: false,
      [name]: event.target.value
    });
  }

  couponApplyVersion = 0;

  render() {
    const {
      applied,
      couponCode,
      couponMessage,
      errorApplying,
      fetching
    } = this.props.coupon;

    const {
      hidden
    } = this.state;

    return (
      <div className="coupon">
        <div className="coupon-container">
          <div className="coupon-toggle" onClick={() => this.setState({hidden: !hidden})}>
            {hidden && <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>}
            {!hidden && <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>}
            <div className="coupon-title">
              Coupon
            </div>
          </div>
          {/* <div className="coupon-description">
            Apply a coupon code
          </div> */}
          
          {!hidden && (
            <div className="coupon-interior">
              <div className="row">
                <div className="col-sm-10">
                <Input
                  id="couponCode"
                  label="Coupon Code"
                  onChange={event => this.handleInputChange('couponCode', event)}
                  value={couponCode}
                  flex
                  type="text"
                  onKeyPress={event => {if (event.key === 'Enter') this.validateCoupon();}}
                />
                </div>
                <div className="col-sm-2">
                  <div
                    className="sofloo-button-small"
                    onClick={this.onClickApply}
                  >
                    {!fetching && 'apply'}
                    {fetching && 'applying...'}
                  </div>
                </div>
              </div>
              {!fetching && applied && (
                <div className="contact-success-message">
                  {couponMessage}
                </div>
              )}
              {errorApplying && (
                <div className="contact-error-message">
                  {errorApplying}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  coupon: state.coupon
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setCoupon: coupon => ({
      type: ACTIONS.SET_COUPON,
      coupon
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupon);
