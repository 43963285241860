import { createStore } from 'redux';

import ACTIONS from './actions';
import {
  EMPTY_SOFLOO_SEED,
  DEFAULT_SHIRT_PLACEMENT,
  DEFAULT_SHIRT_VARIANT_ID,
  PAYMENT_WINDOWS,
  VERSIONS
} from '../constants';
import {
  getTotalPrice,
  getSubtotalFromOrders
} from '../util/orders';

// const testShippingOps = [{
//   "id":"PRINTFUL_MEDIUM",
//   "name":"Standard (3-5 business days after fulfillment)",
//   "rate":"2.81",
//   "currency":"USD"
// },{"id":"PRINTFUL_FAST","name":"Express (1-3 business days after fulfillment)","rate":"3.57","currency":"USD"},{"id":"STANDARD","name":"Flat Rate (3-5 business days after fulfillment)","rate":"5.00","currency":"USD"},{"id":"PRINTFUL_1DAY","name":"Overnight (1 business day after fulfillment)","rate":"32.30","currency":"USD"}];

// const testInitialCustomerInformation = {
//   shippingName: 'Rice Towell',
//   shippingAddress1: '28 Orchard St.',
//   shippingAddress2: 'Apt 4A',
//   shippingZip: '10002',
//   shippingCity: 'New York',
//   shippingCountry: 'US',
//   shippingCountrySelected: {
//     value: 'US',
//     label: 'United States of America',
//     states: [{
//       value: 'NY',
//       label: 'New York'
//     },{
//       value: 'WV',
//       label: 'West Virginia'
//     }]
//   }, // Used for the html select.
//   shippingRegion: 'NY',
//   shippingRegionSelected: {
//     value: 'NY',
//     label: 'New York'
//   }, // Used for the html select.

//   email: 'rhysh@live.com',
// };

const initialCustomerInformation = {
  email: '',

  shippingName: '',
  shippingAddress1: '',
  shippingAddress2: '',
  shippingZip: '',
  shippingCity: '',
  shippingCountry: '',
  shippingCountrySelected: null, // Used for the html select.
  shippingRegion: '',
  shippingRegionSelected: null, // Used for the html select.
};

const initialCoupon = {
  applied: false,
  fetching: false,
  freeShirts: 0,
  discountPercentage: 0,
  couponCode: '',
  couponMessage: '',
  errorApplying: false
};

function getInitialState() {  
  const storageOrders = JSON.parse(localStorage.getItem('orders'));
  const orders = storageOrders ? storageOrders : [];
  const subtotal = getSubtotalFromOrders(orders, initialCoupon);

  const initialState = {
    coupon: initialCoupon,
    customerInformation: {
      ...initialCustomerInformation
      // ...testInitialCustomerInformation
    },
    orders,
    makingOrder: false,
    paymentWindow: PAYMENT_WINDOWS.BUILD_ORDER,

    selectedShippingOption: {},
    shippingOptions: [],
    // When false, the user's address is not available to ship to.
    shippingOptionsAvailable: false,
    // When true we've loaded the shipping options.
    shippingOptionsLoaded: false,

    showContactUsWindow: false,

    haveSoflooGenerated: false,
    soflooRenders: {},
    soflooRenderUrl: '',

    variantId: DEFAULT_SHIRT_VARIANT_ID,
    soflooRef: null,
    shirtPlacement: DEFAULT_SHIRT_PLACEMENT,
    soflooSeed: EMPTY_SOFLOO_SEED,
    soflooVersion: VERSIONS.FULL_RANDOM,

    stripeToken: '',

    subtotal,
    totalPrice: null
  };

  return initialState;
}

function getSoflooRenderUrl(soflooRenders, shirtPlacement) {
  return soflooRenders ? soflooRenders[shirtPlacement] : '';
}

function reducer(state = getInitialState(), action) {
  switch (action.type) {
    case ACTIONS.ADD_TO_ORDERS: {
      const newOrders = [
        action.newOrder,
        ...state.orders
      ];
      // We also save orders in the local store so a user can come back to them.
      localStorage.setItem('orders', JSON.stringify(newOrders));

      return {
        ...state,
        orders: newOrders,
        subtotal: getSubtotalFromOrders(newOrders, state.coupon)
      };
    }
    case ACTIONS.CLEAR_ORDERS: {
      localStorage.removeItem('orders');

      return {
        ...state,
        orders: [],
        subtotal: 0,
        totalPrice: 0
      };
    }
    case ACTIONS.SET_HAVE_SOFLOO_GENERATED: {
      return {
        ...state,
        haveSoflooGenerated: action.haveSoflooGenerated
      };
    }
    case ACTIONS.SET_COUPON: {
      const newCoupon = {
        ...state.coupon,
        ...action.coupon
      };

      return {
        ...state,
        coupon: newCoupon,
        subtotal: getSubtotalFromOrders(state.orders, newCoupon),
        totalPrice: getTotalPrice(state.orders, state.selectedShippingOption ? state.selectedShippingOption.rate : 0, newCoupon)
      };
    }
    case ACTIONS.SET_SOFLOO: {
      
      if (action.soflooRef) {
        return {
          ...state,
          soflooRef: action.soflooRef,
          soflooSeed: action.soflooSeed,
          soflooVersion: action.soflooVersion
        };
      } else {
        return {
          ...state,
          soflooSeed: action.soflooSeed,
          soflooVersion: action.soflooVersion
        };
      }
    }
    case ACTIONS.SET_CONTACT_WINDOW: {
      return {
        ...state,
        showContactUsWindow: action.show
      };
    }
    case ACTIONS.UPDATE_ORDERS:
      const updatedOrders = [...action.newOrders];
      // We also save orders in the local store so a user can come back to them.
      localStorage.setItem('orders', JSON.stringify(updatedOrders));

      return {
        ...state,
        orders: updatedOrders,
        subtotal: getSubtotalFromOrders(updatedOrders, state.coupon)
      };
    case ACTIONS.SELECT_SHIPPING_OPTION:
      const newTotalPrice = getTotalPrice(state.orders, action.newShippingOption.rate, state.coupon);

      return {
        ...state,
        selectedShippingOption: action.newShippingOption,
        totalPrice: newTotalPrice
      };
    case ACTIONS.SET_MAKING_ORDER:
      return {
        ...state,
        makingOrder: action.makingOrder
      };
    case ACTIONS.SET_PAYMENT_WINDOW:
      let update = {
        paymentWindow: action.newPaymentWindow
      };

      if (update.paymentWindow === PAYMENT_WINDOWS.SHIPPING && state.paymentWindow === PAYMENT_WINDOWS.CUSTOMER_INFORMATION) {
        update.shippingOptionsLoaded = false;
      }

      return {
        ...state,
        ...update
      };
    case ACTIONS.SET_SHIPPING_OPTIONS:
      const shippingOptionsAvailable = action.newShippingOptions && action.newShippingOptions.length > 0;

      const totalPrice = shippingOptionsAvailable ? getTotalPrice(state.orders, action.newShippingOptions[0].rate, state.coupon) : null;
      
      return {
        ...state,
        selectedShippingOption: shippingOptionsAvailable ? action.newShippingOptions[0] : {},
        shippingOptionsAvailable,
        shippingOptionsLoaded: true,
        shippingOptions: action.newShippingOptions,
        totalPrice
      };
    case ACTIONS.SET_STRIPE_TOKEN:
      return {
        ...state,
        stripeToken: action.stripeToken
      };
    case ACTIONS.SET_SHIRT_PLACEMENT:
      return {
        ...state,
        shirtPlacement: action.shirtPlacement,
        soflooRenderUrl: getSoflooRenderUrl(state.soflooRenders, action.shirtPlacement)
      };
    case ACTIONS.SET_SHIRT_VARIANT:
      return {
        ...state,
        variantId: action.newVariantId
      };
    case ACTIONS.SET_SOFLOO_RENDERS:
      return {
        ...state,
        soflooRenders: action.soflooRenders,
        soflooRenderUrl: getSoflooRenderUrl(action.soflooRenders, state.shirtPlacement)
      };
    case ACTIONS.UPDATE_CUSTOMER_INFORMATION: {
      return {
        ...state,
        customerInformation: {
          ...state.customerInformation,
          ...action.customerInformationUpdate
        }
      };
    }
    default:
      return state
  }
}

let store = createStore(reducer);

export default store;
