import MersenneTwister from 'mersennetwister';

import {
  createRandomSeed
} from './seeds';

import {
  generateInitialShape,
  generateRandomShape,
  generateRandomTopologyShape,
  generateRandomTriangles,
  generateRandomWaterColorShape
} from './shapes';

import { /* MOBILE_FRIENDLY_VERSIONS, */ VERSIONS } from '../constants';

export const generateRandomLayout = (width, height, version) => {
  const seed = createRandomSeed();

  return generateLayoutBySeedAndVersion(width, height, seed, version);
};

function isRestrictedVersion(version) {
  return version === VERSIONS.FULL_RANDOM || version === VERSIONS.INIT_FIRST_GEN || version === VERSIONS.TRIANGLES_MULTI || version === VERSIONS.BASIC_FIRST_GEN_GRADIENTS || version === VERSIONS.LINES;
}

// function getRandomMobileFriendlyVersion(version) {
//   const mobileVersionKeys = Object.keys(MOBILE_FRIENDLY_VERSIONS);
//   return MOBILE_FRIENDLY_VERSIONS[mobileVersionKeys[Math.floor(Math.random() * mobileVersionKeys.length)]];
// }

export const generateLayoutBySeedAndVersion = (width, height, seed, layoutVersion) => {
  let version = layoutVersion;

  const shapeOptions = {
    // Currently we only allow lines.
    strokePath: version === VERSIONS.LINES
  };

  if (version === VERSIONS.LINES) {
    version = VERSIONS.FULL_RANDOM;
  }

  if (version === VERSIONS.FULL_RANDOM) {
    const versionKeys = Object.keys(VERSIONS);
    while (isRestrictedVersion(version)) {
      version = VERSIONS[versionKeys[Math.floor(Math.random() * versionKeys.length)]];
    }

    // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    //   version = getRandomMobileFriendlyVersion();
    // }
  } else {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // They're on mobile here... We should make sure we're not rendering a design that isn't mobile friendly.
      //       ¯\_(ツ)_/¯
    }
  }

  const layout = {
    height,
    seed,
    version,
    width
  };

  const seeder = new MersenneTwister(seed);

  switch (version) {
    case VERSIONS.BASIC_FIRST_GEN:
      layout.shapes = [generateRandomShape(width, height, seeder, shapeOptions)];
      break;

    case VERSIONS.BASIC_FIRST_GEN_BW:
      layout.shapes = [generateRandomShape(width, height, seeder, {
        ...shapeOptions,
        blackAndWhite: true
      })];
      break;

    case VERSIONS.BASIC_FIRST_GEN_GRADIENT_PACK:
      layout.shapes = [generateRandomShape(width, height, seeder, {
        ...shapeOptions,
        gradientPack: true
      })];
      break;

    case VERSIONS.BASIC_FIRST_GEN_GRADIENTS: {
      layout.shapes = [generateRandomShape(width, height, seeder, {
        ...shapeOptions,
        colorDropWithDepth: true,
        gradientPack: true,
        gradientColor: true
      })];
      break;
    }

    case VERSIONS.INIT_FIRST_GEN:
      layout.shapes = [generateInitialShape(width, height, seeder, shapeOptions)];
      break;

    case VERSIONS.TOPOLOGY:
      layout.shapes = [generateRandomTopologyShape(width, height, seeder, shapeOptions)];
      break;

    case VERSIONS.TOPOLOGY_GRADIENTS: {
      const options = {
        ...shapeOptions,
        colorDropWithDepth: true,
        gradientPack: true,
        gradientColor: true
      };
      layout.shapes = [generateRandomTopologyShape(width, height, seeder, options)];
      break;
    }

    case VERSIONS.TOPOLOGY_GRADIENT_PACK:
      layout.shapes = [generateRandomTopologyShape(width, height, seeder, { ...shapeOptions, gradientPack: true })];
      break;

    case VERSIONS.TRIANGLES:
      layout.shapes = [generateRandomShape(width, height, seeder, { ...shapeOptions, triangleMode: true })];
      break;

    case VERSIONS.TRIANGLES_MULTI:
      layout.shapes = generateRandomTriangles(width, height, seeder, shapeOptions);
      break;

    case VERSIONS.WATER_COLOR:
      layout.shapes = [generateRandomWaterColorShape(width, height, seeder, shapeOptions)];
      break;

    default:
      layout.shapes = [generateRandomShape(width, height, seeder, shapeOptions)];
      break;
  }

  return layout;
};
