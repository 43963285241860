import React, { Component } from 'react';

import Header from '../header';

import './page-not-found.css';

class PageNotFound extends Component {
  render() {
    return (
      <div className="pnf">
        <Header />
        <div className="pnf-container">
          <div className="pnf-title">
            Page not found.
          </div>
          <a
            className="pnf-home-link"
            href="/"
            rel="noopener noreferrer"
          >
            Home
          </a>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
