// import PropTypes from 'prop-types';
import React, { Component } from 'react';

import './order-status.css';

import { getOrderIdFromURL } from '../../util/url';
import { getDescriptionForStatus } from '../../util/orders';
import WebService from '../../web-service';

import Header from '../header';
import Loader from '../loader';

class OrderStatus extends Component {
  static propTypes = {

  }

  static defaultProps = {
  }

  state = {
    failedToLoadOrder: false,
    failedToLoadOrderMessage: '',
    manufacturingStatus: 'unknown',
    orderStatusLoaded: false,
    previewUrl: '',
    shipments: [],
    statusDescription: ''
  }

  loadOrderState = async () => {
    console.log('getOrderIdFromURL', getOrderIdFromURL());

    let orderStatus;
    try {
      orderStatus = await WebService.getOrderStatus(this.orderId);
    } catch (e) {
      console.log('error', e);
      this.setState({
        failedToLoadOrder: true,
        failedToLoadOrderMessage: e.message,
        orderStatusLoaded: true
      });
      return;
    }

    const {
      manufacturingStatus,
      previewUrl,
      shipments
    } = orderStatus;

    const statusDescription = getDescriptionForStatus(manufacturingStatus);

    this.setState({
      failedToLoadOrder: false,
      orderStatusLoaded: true,
      manufacturingStatus,
      previewUrl,
      shipments,
      statusDescription
    });
  }

  componentDidMount() {
    this.orderId = getOrderIdFromURL();
    if (!this.orderId) {
      this.setState({
        failedToLoadOrder: true,
        failedToLoadOrderMessage: 'No order is supplied on the url. Please double check your email for the link to your order.',
        orderStatusLoaded: true
      });
    } else {
      this.loadOrderState();
    }
  }

  render() {
    const {
      failedToLoadOrder,
      failedToLoadOrderMessage,
      manufacturingStatus,
      orderStatusLoaded,
      previewUrl,
      shipments,
      statusDescription
    } = this.state;

    return (
      <React.Fragment>
        <Header />
        <div
          className="order-status-container"
        >
          <div className="order-status-title">
            your sofloo order
          </div>
          {failedToLoadOrder && (
            <div className="order-status-failed">
              <div className="order-status-failed-text">
                Unable to load the order's status: {failedToLoadOrderMessage}
              </div>
            </div>
          )}
          {!orderStatusLoaded && (
            <div className="order-status-loading">
              <div className="order-status-loading-text">
                Loading...
              </div>
              <Loader />
            </div>
          )}
          {orderStatusLoaded && !failedToLoadOrder && (
            <div className="order-status-info-container">
              <div className="row">
                <div className="col-3">
                  <img
                    className="order-status-preview-image"
                    src={previewUrl}
                    alt="Preview"
                  />
                </div>
                <div className="col-9">
                  <div className="order-status-text">
                    Order status: <strong>{manufacturingStatus}</strong>
                  </div>
                  <div className="order-status-description">
                    {statusDescription}
                  </div>
                </div>
              </div>
              {shipments && shipments.length > 0 && (
                <div>
                  {shipments.map(shipment => {
                    return (
                      <div className="order-status-shipments">
                        <div className="order-status-shipment">
                          Carrier: {shipment.service}
                        </div>
                        <div>
                          Shipped date: {shipment.shippedDate}
                        </div>
                        <div className="order-status-shipment">
                          Tracking url:&nbsp;
                            <a
                              className="order-status-shipment-url"
                              href={shipment.trackingUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {shipment.trackingUrl}
                            </a>
                        </div>
                        <div className="order-status-shipment">
                          Tracking code: {shipment.trackingCode}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {(!shipments || shipments.length < 1) && (
                <div className="order-status-shipments">
                  Once your order has been shipped from the facility, a tracking number and url will appear here.
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default OrderStatus;
