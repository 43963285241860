function getRecipientFromCustomerInfo(customerInformation) {
  return {
    address1: customerInformation.shippingAddress1,
    address2: customerInformation.shippingAddress2,
    city: customerInformation.shippingCity,
    country_code: customerInformation.shippingCountry,
    state_code: customerInformation.shippingRegion,
    zip: customerInformation.shippingZip
  };
}

function getItemsFromOrders(orders) {
  return orders.map(orderItem => {
    return {
      quantity: Number(orderItem.quantity),
      variant_id: orderItem.variantId,
      files: [{
        url: orderItem.soflooRenderUrl
      }]
    };
  });
}

const imageBucket = 'sofloo';

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// Fetch errors when the internet goes down or cant comelete normally.
// 500 or 404 does not erorr. just response.ok = false.
// We should handle the errors better.
class WebService {
  getOrderStatus = async (orderId) => {
    const response = await fetch(`order/status?id=${orderId}`);

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const orderInfo = await response.json();

    return orderInfo;
  }

  // Order is an array of order objects.
  // Each order object has a quantity and size.
  getShippingOptions = async (customerInformation, orderItems) => {
    let recipient = getRecipientFromCustomerInfo(customerInformation);

    const items = getItemsFromOrders(orderItems);

    // https://www.printful.com/docs/shipping
    const response = await fetch('/shipping/rates', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        recipient,
        items,
        currency: 'USD'
      })
    });

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const { shippingOptions } = await response.json();

    return shippingOptions;
  }

  makeOrder = async (orders, customerInformation, selectedShippingOption, coupon, totalPrice, soflooSeed, soflooVersion, orderId, stripeToken) => {
    const response = await fetch('/order/create', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        coupon,
        customerInformation,
        clientOrders: orders,
        selectedShippingOption,
        clientTotalPrice: totalPrice,
        soflooSeed,
        soflooVersion,
        orderId,
        stripeToken
      })
    });

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const { customerId } = await response.json();

    return customerId;
  }

  getImageUrl = (imagePlacement, soflooSeed, soflooVersion) => {
    const fileName = `${imagePlacement}-${soflooSeed}-${soflooVersion}.png`;
    return `https://${imageBucket}.s3.amazonaws.com/${fileName}`;
  }

  seeIfImageExists(src){
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img.height);
      img.onerror = reject;
      img.src = src;
    })
  }

  getSoflooRenders = async () => {
    const response = await fetch(`image/renders`);

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const { soflooRenders } = await response.json();

    return soflooRenders;
  }

  doesImageExist = async (imagePlacement, soflooSeed, soflooVersion) => {
    const url = this.getImageUrl(imagePlacement, soflooSeed, soflooVersion);
    const imageSuccess = await this.seeIfImageExists(url);
    return !!imageSuccess;
  }

  handleFileUpload = async (imagePlacement, imageFile, soflooSeed, soflooVersion) => {
    const awaitResponse = await fetch('image/upload', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        imageFile,
        imagePlacement,
        soflooSeed,
        soflooVersion
      })
    });

    if (!awaitResponse.ok) {
      const responseText = await awaitResponse.text();
      throw Error(responseText);
    }

    const response = await awaitResponse.json();

    const { status, fileName } = response;

    if (status !== 'success') {
      console.log('err from image response', JSON.parse(response).error);
      throw Error('Unable to upload image.');
    }

    return `https://${imageBucket}.s3.amazonaws.com/${fileName}`;
  }
}

export default new WebService();
