import React, { Component } from 'react';

import { isStringAndIsInBounds } from '../../util/type';

import './contact-us.css';

import Input from '../inputs';
import Loader from '../loader';

class Contact extends Component {
  state = {
    email: '',
    errorSending: false,
    message: '',
    subject: '',
    sending: false,
    sent: false
  }

  componentDidMount() {
    document.title = `Sofloo - Contact`;
  }

  onClickSend = async () => {
    const validationError = this.validate();

    if (validationError) {
      this.setState({
        errorSending: validationError
      });
      return;
    }

    const {
      email,
      subject,
      message
    } = this.state;

    this.setState({
      sending: true
    });

    try {
      const response = await fetch('/contact', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          subject,
          message
        })
      });

      if (!response.ok) {
        const text = await response.text();

        this.setState({
          errorSending: text,
          sending: false
        });
        return;
      }

      // Success!
      this.setState({
        errorSending: false,
        sending: false,
        sent: true
      });
    } catch (e) {
      this.setState({
        errorSending: 'Unable to communicate with server. Please refresh and try again.',
        sending: false
      });
    }
  }

  validate = () => {
    const {
      email,
      message,
      subject
    } = this.state;

    if (!isStringAndIsInBounds(email, 2, 120)) {
      return 'Please enter a valid email.';
    }
    if (!isStringAndIsInBounds(subject, 1, 300)) {
      return 'Please enter a valid message subject.';
    }
    if (!isStringAndIsInBounds(message, 2, 10000)) {
      if (message && message.length > 10000) {
        return 'That message is too long sorry - please shorten it.';
      }
      return 'Please enter a valid message.';
    }

    return false;
  }

  handleInputChange = (name, event) => {
    this.setState({
      errorSending: false,
      [name]: event.target.value
    });
  }

  render() {
    const {
      email,
      errorSending,
      message,
      sending,
      sent,
      subject
    } = this.state;

    return (
      <div className="contact">
        <div className="contact-background"/>
        <div className="contact-container">
          <div className="contact-title">
            Contact us
          </div>
          <div className="contact-description">
            You can also reach out to us at&nbsp;
            <a
              className="contact-email-link"
              href="mailto:support@sofloo.com?Subject=Sofloo%20contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>support@sofloo.com</strong>
            </a>
          </div>
          
          {!sending && !sent && (
            <div>
              <Input
                id="email"
                label="Contact Email"
                onChange={event => this.handleInputChange('email', event)}
                value={email}
                type="email"
              />
              <Input
                id="subject"
                label="Message Subject"
                onChange={event => this.handleInputChange('subject', event)}
                value={subject}
              />
              <div className="contact-body-title">
                Message
              </div>
              <textarea
                className="contact-body"
                id="message"
                name="message"
                placeholder="Type your message to us here"
                onChange={event => this.handleInputChange('message', event)}
                value={message}
              />
              <div className="contact-send-button-container">
                <div
                  className="sofloo-button"
                  onClick={this.onClickSend}
                >
                  Send
                </div>
              </div>
            </div>
          )}
          {sending && !sent && (
            <div>
              <div className="contact-sending-message">
                Sending...
              </div>
              <Loader />
            </div>
          )}
          {!sending && sent && (
            <div className="contact-success-message">
              Success. Your message has been sent. We'll get back to you in the next few days.
            </div>
          )}
          {errorSending && (
            <div className="contact-error-message">
              {errorSending}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Contact;
