exports.VERSIONS = {
  BASIC_FIRST_GEN: 'gen-1',
  BASIC_FIRST_GEN_BW: 'gen-1-bw',
  BASIC_FIRST_GEN_GRADIENT_PACK: 'g-1-cr',
  BASIC_FIRST_GEN_GRADIENTS: 'g-1-cr-gr',
  FULL_RANDOM: 'rnd',
  INIT_FIRST_GEN: 'gen-1-1',
  LINES: 'lines-1',
  TOPOLOGY: 'top-1',
  TOPOLOGY_GRADIENTS: 'top-1-cr-gr',
  TOPOLOGY_GRADIENT_PACK: 'top-1-cr',
  TRIANGLES: 'tr-1',
  TRIANGLES_MULTI: 'tr-1-m',
  WATER_COLOR: 'wc-1'
};

exports.EMPTY_SOFLOO_SEED = -1;

exports.MOBILE_FRIENDLY_VERSIONS = {
  BASIC_FIRST_GEN: 'gen-1',
  BASIC_FIRST_GEN_BW: 'gen-1-bw',
  BASIC_FIRST_GEN_GRADIENT_PACK: 'g-1-cr',
  BASIC_FIRST_GEN_GRADIENTS: 'g-1-cr-gr',
  INIT_FIRST_GEN: 'gen-1-1',
  TRIANGLES: 'tr-1',
  WATER_COLOR: 'wc-1'
};

exports.SHIRT_UNIT_PRICE = 25;

// The `value`s here are what printful uses.
const SHIRT_SIZE_OPTIONS = [
  { value: 'extra-small', label: 'x-small' },
  { value: 'small', label: 'small' },
  { value: 'medium', label: 'medium' },
  { value: 'large', label: 'large' },
  { value: 'extra-large', label: 'x-large' },
  { value: 'extra-extra-large', label: 'xx-large' }
];
exports.SHIRT_SIZE_OPTIONS = SHIRT_SIZE_OPTIONS;

/**
We want 300 dpi.
Image is 12×16
3600x4800
 */
// exports.imageOutputWidth = 1800;
// exports.imageOutputHeight = 1800;
// exports.SHIRT_PLACEMENTS = {
//   pocket: {
//     thumbnailImage: 'pocket',
//     leftPosition: 1050,
//     topPosition: 160,
//     width: 500,
//     height: 500
//   },
//   full: {
//     thumbnailImage: 'full',
//     leftPosition: 1050,
//     topPosition: 160,
//     width: 1800,
//     height: 1800
//   }
// };

// 150 DPI:
// Area width 1800
// Area height 2400
// https://www.theverge.com/tldr/2018/10/18/17995368/apple-october-30th-event-custom-logo-roundup
// exports.imageOutputWidth = 1800;
// exports.imageOutputHeight = 2400;
// exports.SHIRT_PLACEMENTS = {
//   pocket: {
//     thumbnailImage: 'pocket',
//     left: 1050,
//     top: 160,
//     width: 500,
//     height: 500
//   },
//   full: {
//     thumbnailImage: 'full',
//     left: 0,
//     top: 0,
//     width: 1800,
//     height: 1800
//   }
// };

// 200 DPI:
// Area width 2400
// Area height 3200
// exports.imageOutputWidth = 2400;
// exports.imageOutputHeight = 3200;
// const SHIRT_PLACEMENTS = {
//   pocket: {
//     thumbnailImage: 'pocket',
//     left: 1460,
//     top: 400,
//     width: 700,
//     height: 700
//   },
//   full: {
//     thumbnailImage: 'full',
//     left: 0,
//     top: 220,
//     width: 2400,
//     height: 2400
//   }
// };

// 300 DPI:
// Area width 3600
// Area height 4800
exports.imageOutputWidth = 3600;
exports.imageOutputHeight = 4800;
const SHIRT_PLACEMENTS = {
  pocket: {
    thumbnailImage: 'pocket',
    left: 2190,
    top: 600,
    width: 1050,
    height: 1050
  },
  full: {
    thumbnailImage: 'full',
    left: 0,
    top: 330,
    width: 3600,
    height: 3600
  }
};

exports.SHIRT_PLACEMENTS = SHIRT_PLACEMENTS;
exports.DEFAULT_SHIRT_PLACEMENT = Object.keys(SHIRT_PLACEMENTS)[0];

const SHIRT_COLORS = {
  white: 'white',
  black: 'black'
};
exports.SHIRT_COLORS = SHIRT_COLORS;

exports.soflooHeight = 500;
exports.soflooWidth = 500;

exports.PAYMENT_WINDOWS = {
  BUILD_ORDER: 'BUILD_ORDER',
  CHECKOUT: 'CHECKOUT',
  CONFIRMATION: 'CONFIRMATION',
  CUSTOMER_INFORMATION: 'CUSTOMER_INFORMATION',
  SHIPPING: 'SHIPPING'
};

// TODO: Each variant has its own print area.
// Products - have various products.
// We need to grab this and size accordingly?
// https://www.printful.com/docs/generator#actionPrintfiles
// Maybe this https://www.printful.com/docs/generator#actionTemplates
// Need to check out gildan ladies 2000L and 5000L
// https://www.printful.com/products
// This is unisex softstyle 64000 gildan. - Not that good of a shirt.
// const SHIRT_VARIANTS = [
//   { color: 'white', size: 'S', id: 473 },
//   { color: 'white', size: 'M', id: 504 },
//   { color: 'white', size: 'L', id: 535 },
//   { color: 'white', size: 'XL', id: 566 },
//   { color: 'white', size: '2XL', id: 597 },

//   { color: 'black', size: 'S', id: 474 },
//   { color: 'black', size: 'M', id: 505 },
//   { color: 'black', size: 'L', id: 536 },
//   { color: 'black', size: 'XL', id: 567 },
//   { color: 'black', size: '2XL', id: 598 }
// ];

// TODO: We can actually pull this from https://www.printful.com/docs/catalog if we want to.

// This is Next Level 3600.
const SHIRT_VARIANTS = {
  // White
  '4872': { color: SHIRT_COLORS.white, size: 'extra-small', id: '4872' },
  '4873': { color: SHIRT_COLORS.white, size: 'small', id: '4873' },
  '4874': { color: SHIRT_COLORS.white, size: 'medium', id: '4874' },
  '4875': { color: SHIRT_COLORS.white, size: 'large', id: '4875' },
  '4876': { color: SHIRT_COLORS.white, size: 'extra-large', id: '4876' },
  '4877': { color: SHIRT_COLORS.white, size: 'extra-extra-large', id: '4877' },

  // Black
  '4865': { color: SHIRT_COLORS.black, size: 'extra-small', id: '4865' },
  '4866': { color: SHIRT_COLORS.black, size: 'small', id: '4866' },
  '4867': { color: SHIRT_COLORS.black, size: 'medium', id: '4867' },
  '4868': { color: SHIRT_COLORS.black, size: 'large', id: '4868' },
  '4869': { color: SHIRT_COLORS.black, size: 'extra-large', id: '4869' },
  '4870': { color: SHIRT_COLORS.black, size: 'extra-extra-large', id: '4870' }
};
exports.SHIRT_VARIANTS = SHIRT_VARIANTS;
exports.VARIANT_IDS = Object.keys(SHIRT_VARIANTS);
exports.DEFAULT_SHIRT_VARIANT_ID = '4874';
